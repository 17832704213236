import CarListReportItem from "../handlers/get/CarListReportItem";
import CarListReportStore from "../stores/CarListReportStore";
import {makeAutoObservable} from "mobx";
import GetCarListReportRequest from "../handlers/get/GetCarListReportRequest";
import GetCarListReportHandler from "../handlers/get/GetCarListReportHandler";
import GetCarListReportResponse from "../handlers/get/GetCarListReportResponse";
import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import {message} from "antd";
import UserContext from "../../../identity/contexts/UserContext";
import ObjectHelper from "../../../app/utils/ObjectHelper";

export default class GetCarListReportViewModel {
    columns: any[];
    carListReportList: CarListReportItem[];
    carListReportExport: CarListReportItem[];
    totalSize: number;
    isProcessing: boolean;
    errorMessage: string;
    getCarListReportesRequest: GetCarListReportRequest;

    constructor(public carListReportStore: CarListReportStore) {
        makeAutoObservable(this);

    }

    public async getAllCarListReport(getCarListReportesRequest: GetCarListReportRequest, exportToFile: boolean = false) {
        try {
            this.errorMessage = "";
            if(ObjectHelper.isNullOrEmptyWithDefaultExceptions(getCarListReportesRequest, [])){
                this.errorMessage = i18next.t("General.Search.AtLeastOne");
                return;
            }

            this.isProcessing = true;
            getCarListReportesRequest.exportToFile = exportToFile;
            let response = await GetCarListReportHandler.get(getCarListReportesRequest);

            if (response && response.success) {

                let result = response.data;
                let items = result.items;

                if(exportToFile)
                    this.carListReportExport = items;
                else
                {
                    this.carListReportList = items;
                    this.totalSize = result.totalCount;
                }
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('CarListReportes.Error.Get.Message');
            log.error(e);
        } finally {
            getCarListReportesRequest.exportToFile = false;
            this.isProcessing = false;
        }
    }

}
