import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import EditRechargeBalanceViewModel from "../view-models/EditRechargeBalanceViewModel";
import GetRechargeBalanceViewModel from "../view-models/GetRechargeBalanceViewModel";
import ListPetropayAccountViewModel from "../../PetropayAccounts/view-models/ListPetropayAccountViewModel";
import ListCompanyViewModel from "../../companies/view-models/ListCompanyViewModel";
import UserContext from "identity/contexts/UserContext";
import NavigationService from "app/services/NavigationService";
import GetRechargeBalanceResponse from "../handlers/get/GetRechargeBalanceResponse";
import i18next from "i18next";

export default class RechargeBalanceStore
{
    getRechargeBalanceViewModel: GetRechargeBalanceViewModel;
    editRechargeBalanceViewModel: EditRechargeBalanceViewModel;
    listPetropayAccountViewModel: ListPetropayAccountViewModel;
    listCompanyViewModel: ListCompanyViewModel;
       errorMessage: string;

    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onRechargeBalanceGetPageLoad()
    {
        
    
      


        if(UserContext.info.role !== 1 && UserContext.info.role !== 100  && UserContext.info.role !== 5 ){

        
               NavigationService.navigate('/');
            }else{
                if(UserContext.info.role == 5 && UserContext.info.companyBranchAllowedRechargeBalance==false){
                    NavigationService.navigate('/');
        
                
                }
            }
        
        
    


        this.getRechargeBalanceViewModel = new GetRechargeBalanceViewModel(this);
        




    }

    onRechargeBalanceGetPageUnload()
    {
        this.getRechargeBalanceViewModel = null;
       
    }

    onRechargeBalanceEditPageLoad()
    {

   

       
        if(UserContext.info.role !== 1 && UserContext.info.role !== 100  && UserContext.info.role !== 5 ){

        
            NavigationService.navigate('/');
         }else{
             if(UserContext.info.role == 5 && UserContext.info.companyBranchAllowedRechargeBalance==false){
                 NavigationService.navigate('/');
     
             
             }
         }
     
        
        this.listPetropayAccountViewModel = new ListPetropayAccountViewModel();
        this.listCompanyViewModel = new ListCompanyViewModel();
        this.editRechargeBalanceViewModel = new EditRechargeBalanceViewModel(this);
    }

    onRechargeBalanceEditPageUnload()
    {
        this.listPetropayAccountViewModel = null;
        this.editRechargeBalanceViewModel = null;
        this.listCompanyViewModel = null;
    }

}
