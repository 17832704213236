import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import Stores from "app/constants/Stores";
import i18next from "i18next";
import {Card, Col, Descriptions, Divider, Row, Spin, Table} from "antd";
import PetrolStationItemColumns from "./PetrolStationItemColumns";
import CompanyListItemColumns from "./CompanyListItemColumns";
import AdminStore from 'admin/stores/AdminStore';
import { values } from 'mobx';

import { Chart } from 'highcharts';
import { HighchartsChartComponent } from 'highcharts-angular';
import Meta from 'antd/lib/card/Meta';

interface DashboardProps {
    adminStore?: AdminStore
}

const AdminDashboard: React.FC<DashboardProps> = inject(Stores.adminStore)(observer(({adminStore}) =>
{
    const [dataFetched, setDataFetched] = React.useState(false);
    const [sumCompaniesBalance, setSumCompaniesBalance] = React.useState(0);
    const [sumPetrolStationsBalance, setSumPetrolStationsBalance] = React.useState(0);
    const [sumPetrolStationsBonus, setSumPetrolStationsBonus] = React.useState(0);
    PetrolStationItemColumns.forEach(w => {
        w.title = i18next.t(w.title);
    });

    const petrolStationsColumns: any[] = [...PetrolStationItemColumns];

    


    CompanyListItemColumns.forEach(w => {
        w.title = i18next.t(w.title);
    });

    const companyColumns: any[] = [...CompanyListItemColumns];

    useEffect(() => {
        onLoad();

        return onUnload;
    }, []);

    async function onLoad() {
        adminStore.onAdminGetPageLoad();
        await adminStore.getAdminViewModel.getDashboardData();

        if(adminStore.getAdminViewModel?.companyListItems && adminStore.getAdminViewModel?.companyListItems?.length > 0)
            adminStore.getAdminViewModel.sumCompaniesBalance = adminStore.getAdminViewModel.companyListItems.map(w => w.balance ?? 0).reduce((a, b) => a + b, 0);
        if(adminStore.getAdminViewModel?.petrolStationItems && adminStore.getAdminViewModel?.petrolStationItems?.length > 0)
            adminStore.getAdminViewModel.sumPetrolStationsBalance = adminStore.getAdminViewModel.petrolStationItems.map(w => w.balance ?? 0).reduce((a, b) => a + b, 0);

         if(adminStore.getAdminViewModel?.petrolStationItems && adminStore.getAdminViewModel?.petrolStationItems?.length > 0)
            adminStore.getAdminViewModel.sumPetrolStationsBonus = adminStore.getAdminViewModel.petrolStationItems.map(w => w.bonus ?? 0).reduce((a, b) => a + b, 0);
       
            setDataFetched(true);
    }

    let viewModel = adminStore.getAdminViewModel;

    if (!viewModel) return;

    function onUnload() {
        adminStore.onAdminGetPageUnload();
    }

    return (
        <div>
        {dataFetched ?
            <div   >


          
                 
      
   
     {/* 
                <  Descriptions  style={  {textTransform:'uppercase'}  } title={i18next.t("AdminDashboard.Title") }  bordered  >
                  
                   <Descriptions.Item  className="dashbord-section" style={{backgroundColor:'#fccb81' }   }  label={i18next.t("AdminDashboard.SubscriptionRequests")}>{viewModel?.subscriptionRequests?.toLocaleString()}</Descriptions.Item>
                   
                    <Descriptions.Item className="dashbord-section" style={{backgroundColor:'#fccb81'}  } label= {i18next.t("AdminDashboard.RechargeRequests" ) } >{viewModel?.rechargeRequests?.toLocaleString()}  </Descriptions.Item>
                    
                    <Descriptions.Item className="dashbord-section" style={{backgroundColor:'#fccb81'}  }label={i18next.t("AdminDashboard.CarRequests")}>{viewModel?.carRequests?.toLocaleString()}</Descriptions.Item>
                
                </Descriptions>
*/}


  <Row className='row-card'  justify="space-around" align="bottom">
      <Col span={6}>
       
      <Card.Grid  className='dashbord-cart' style={{ width:270  }} >
        <Meta className='mat-card'
          avatar={<img src="images/balance.png"  width={50} />}
          title={i18next.t("AdminDashboard.RechargeRequests") }
          description={viewModel?.rechargeRequests?.toLocaleString()}
          
        />
      </Card.Grid>


      </Col>
      <Col span={6}>
       
      <Card.Grid  className='dashbord-cart' style={{ width:270 }} >
        <Meta className='mat-card'
          avatar={<img src="images/carBalance.png"  width={50} />}
          title={i18next.t("AdminDashboard.CarRequests")}
          description={viewModel?.carRequests?.toLocaleString()}
        />
      </Card.Grid>   




      </Col>
      
    </Row>

                <br  />
                <Divider className="dashbord-titl" >{ i18next.t("AdminDashboard.Section.CompaniesBalance")}</Divider>
                <br/>
                <Table className="dashbord-table" dataSource={viewModel?.companyListItems} columns={companyColumns} loading={viewModel?.isProcessing}
                       bordered={true} pagination={false}  scroll={{ y: 500 }}
                       
                       
                        summary={() => (
                           <Table.Summary.Row  className='totalTabl'>
                               <Table.Summary.Cell index={0}>{i18next.t("General.Table.Total")}</Table.Summary.Cell>
                               <Table.Summary.Cell colSpan={1} index={1}></Table.Summary.Cell>
                               <Table.Summary.Cell index={5}>{viewModel?.sumCompaniesBalance?.toLocaleString()}</Table.Summary.Cell>
                               <Table.Summary.Cell colSpan={1} index={6}></Table.Summary.Cell>
                           </Table.Summary.Row>
                       )}

                       />
                <br/>
                <Divider className="dashbord-titl" > {i18next.t("AdminDashboard.Section.PetrolStationsBalance") }   </Divider>
                <br/>
                <Table className="dashbord-table"  dataSource={viewModel?.petrolStationItems} columns={petrolStationsColumns} loading={viewModel?.isProcessing}
                      bordered={true} pagination={false} scroll={{ y: 500 }} 
                       
                       summary={() => (
                           <Table.Summary.Row className='totalTabl' >
                               <Table.Summary.Cell index={0}>{i18next.t("General.Table.Total")}</Table.Summary.Cell>
                               <Table.Summary.Cell colSpan={2} index={1}></Table.Summary.Cell>
                               <Table.Summary.Cell index={5}>{viewModel?.sumPetrolStationsBalance?.toLocaleString()}</Table.Summary.Cell>
                               <Table.Summary.Cell index={4}>{viewModel?.sumPetrolStationsBonus?.toLocaleString()}</Table.Summary.Cell>
                           </Table.Summary.Row>
                       )}/>
                <br/>
            </div>
                :
                <Row gutter={[24, 16]}>
                    <Col offset={11} span={8}>
                        <Spin className={"spine"} size="large" />
                    </Col>
                </Row>
        }
        </div>
    )
}));

export default AdminDashboard;
