import InvoiceSummaryByCarItem from "../handlers/get/InvoiceSummaryByCarItem";
import InvoiceSummaryByCarStore from "../stores/InvoiceSummaryByCarStore";
import {makeAutoObservable} from "mobx";

import GetInvoiceSummaryByCarHandler from "../handlers/get/GetInvoiceSummaryByCarHandler";

import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import {message} from "antd";
import UserContext from "../../../identity/contexts/UserContext";
import ObjectHelper from "../../../app/utils/ObjectHelper";
import GetInvoiceSummaryByCarRequest from "../handlers/get/GetInvoiceSummaryByCarRequest";

export default class GetInvoiceSummaryByCarViewModel {
    columns: any[];
    invoiceSummaryByCarList: InvoiceSummaryByCarItem[];
    invoiceSummaryByCarExport: InvoiceSummaryByCarItem[];
    totalSize: number;
    sumInvoiceAmount: number;
    sumServiceFeesAmount: number;
    sumInvoiceFuelConsumptionLiter: number;
    sumTotalInvoiceAndFees:number;
    sumConsumptionValue:number;
    isProcessing: boolean;
    errorMessage: string;
    getInvoiceSummariesByCarRequest: GetInvoiceSummaryByCarRequest = new GetInvoiceSummaryByCarRequest();

    constructor(public invoiceSummaryByCarStore: InvoiceSummaryByCarStore) {
        makeAutoObservable(this);

    }

    public async getAllInvoiceSummaryByCar(getInvoiceSummariesByCarRequest: GetInvoiceSummaryByCarRequest, exportToFile: boolean = false) {
        try {
            this.errorMessage = "";
            if(ObjectHelper.isNullOrEmptyWithDefaultExceptions(getInvoiceSummariesByCarRequest, [])){
                this.errorMessage = i18next.t("General.Search.AtLeastOne");
                return;
            }
            this.isProcessing = true;
            if(exportToFile)
                getInvoiceSummariesByCarRequest.exportToFile = exportToFile;
            let response = await GetInvoiceSummaryByCarHandler.get(getInvoiceSummariesByCarRequest);

            if (response && response.success) {

                let result = response.data;
                let items = result.items;
                if(exportToFile)
                    this.invoiceSummaryByCarExport = items;
                else {
                    this.invoiceSummaryByCarList = items;
                    this.totalSize = result.totalCount;
                    this.sumInvoiceAmount = result.sumInvoiceAmount;
                    this.sumServiceFeesAmount = result.sumServiceFeesAmount;
                    this.sumInvoiceFuelConsumptionLiter=result.sumInvoiceFuelConsumptionLiter;
                    this.sumTotalInvoiceAndFees=result.sumTotalInvoiceAndFees;
                    this.sumConsumptionValue=result.sumConsumptionValue;
                }
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('InvoiceSummaries.Error.Get.Message');
            log.error(e);
        } finally {
            getInvoiceSummariesByCarRequest.exportToFile = false;
            this.isProcessing = false;
        }
    }

}
