import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import { Link } from "react-router-dom";
import "./CarListReportList.scss";
import Stores from "app/constants/Stores";

import {
    Button, Collapse, Col, Row,
    Pagination, Input, Form,
    Table, PageHeader, Space, DatePicker, Select, Alert,
    Switch
} from "antd";
import { CheckOutlined, CloseOutlined,EditOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
import i18next from "i18next";
import GetCarListReportRequest from "../../handlers/get/GetCarListReportRequest";
import UserContext from "../../../../identity/contexts/UserContext";
import CarListReportColumns from "./CarListReportColumns";
import CarListReportStore from "../../stores/CarListReportStore";
import ExportExcel from "../../../../app/utils/ExportExcel";
import ConsumptionTypes from 'app/constants/ConsumptionTypes';
import TrueFalse from 'app/constants/TrueFalse';




const { Panel } = Collapse;
const { Option } = Select;

interface CarListReportListProps {
    carListReportStore?: CarListReportStore
}

const CarListReportList: React.FC<CarListReportListProps> = inject(Stores.carListReportStore)(observer(({carListReportStore}) => {

    const [carOptions, setCarOptions] = React.useState([]);
    const [branchOptions, setBranchOptions] = React.useState([]);
    const [companyOptions, setCompanyOptions] = React.useState([]);
   
    


    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };
    const [form] = Form.useForm();
    CarListReportColumns.forEach(w => {
       w.title = i18next.t(w.title);
    });

    ConsumptionTypes.forEach(w =>{ w.title = i18next.t(w.title) });
    const consumptionTypeOptions = [...ConsumptionTypes];

 
    TrueFalse.forEach(w =>{ w.title = i18next.t(w.title) });
    const TrueFalseopyion = [...TrueFalse];
   
    CarListReportColumns.forEach(w => {
        w.title = i18next.t(w.title);
         if(w.key === "carAutoRecharge")
         {
             w["render"] = (w) => {
                 return  w ? <CheckOutlined   style={{ color: "green", fontWeight:'bold' }}/> : <CloseOutlined style={{ color: "red", fontWeight:'bold'  }}/>
             }
         }
         if(w.key === "carOdometerRecordRequired")
            {
                w["render"] = (w) => {
                    return  w ? <CheckOutlined   style={{ color: "green", fontWeight:'bold' }}/> : <CloseOutlined style={{ color: "red", fontWeight:'bold'  }}/>
                }
            }
            if(w.key === "forecastSystem")
                {
                    w["render"] = (w) => {
                        return  w ? <CheckOutlined   style={{ color: "green", fontWeight:'bold' }}/> : <CloseOutlined style={{ color: "red", fontWeight:'bold'  }}/>
                    }
                }
     });

    //const companyColumn = {title: i18next.t("CarListReportes.Label.companyName"), dataIndex: "companyName", key: "companyName", responsive: ['md']};

    //let columns: any[] = [...CarListReportColumns];
    /*if(UserContext.info.role == 100)
    {
        columns.unshift(companyColumn);
    }*/


         //////////////////////////////////////////

  const columns: any[] = [...CarListReportColumns, {
    title: i18next.t("General.Column.Action"),
    dataIndex: 'operation',
    key: 'action',
   fixed: 'right',
    
    render: (text, record) => (
        
        <div className="inline">
            
           
                 
            {record.carsSettingAccess ==true&& UserContext.info.role == 5 &&
            (

                <Link to={`/app/car/edit/${record.carId}`}>
                    <Button type="default"  icon={<EditOutlined />}
                            title={i18next.t("InvoiceSummaries.Button.InvoiceDetail")} style={{ background: "orange", borderColor: "white" }}/>
                          
                </Link>


                
                )
            }
             {UserContext.info.role == 1 &&
            (
                <Link to={`/app/car/edit/${record.carId}`}>
                <Button type="default"  icon={<EditOutlined />}
                        title={i18next.t("InvoiceSummaries.Button.InvoiceDetail")} style={{ background: "orange", borderColor: "white" }}/>
                      
            </Link>

                )
            }

            {UserContext.info.role == 100 &&
            (
                <Link to={`/app/car/edit/${record.carId}`}>
                <Button type="default"  icon={<EditOutlined />}
                        title={i18next.t("InvoiceSummaries.Button.InvoiceDetail")} style={{ background: "orange", borderColor: "white" }}/>
                      
            </Link>

                )
            } 

            
</div>
        )
    }];
           
  



    ////////////////////////////////////










        

    useEffect(() => {
        onLoad();


        return onUnload;
    }, []);

    async function onLoad() {
        carListReportStore.onCarListReportGetPageLoad();
        carListReportStore.getCarListReportViewModel.getCarListReportesRequest = new GetCarListReportRequest();
        carListReportStore.getCarListReportViewModel.getCarListReportesRequest.pageSize = 20;
        carListReportStore.getCarListReportViewModel.getCarListReportesRequest.pageIndex = 0;
        
        if(UserContext.info.role == 1){
            carListReportStore.getCarListReportViewModel.getCarListReportesRequest.companyId = UserContext.info.id;
        }

        if(UserContext.info.role == 5){
            carListReportStore.getCarListReportViewModel.getCarListReportesRequest.companyBranchId = UserContext.info.id;
        }

       

        try {
            /*if (UserContext.info.role === 100) {
                await carListReportStore.listCompanyViewModel.getCompanyList();
                let companyOptions = [];
                if (carListReportStore.listCompanyViewModel) {
                    for (let item of carListReportStore.listCompanyViewModel.listCompanyResponse.items) {
                        companyOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setCompanyOptions(companyOptions);
            }*/

            //await carListReportStore.listBranchViewModel.getBranchList();
            await carListReportStore.listCarViewModel.getCarList();


            //await carListReportStore.getCarListReportViewModel.getAllCarListReport(carListReportStore.getCarListReportViewModel.getCarListReportesRequest);



            
           
            if(UserContext.info.role === 1) {
                await carListReportStore.listBranchViewModel.getBranchList();
                let branchOptions = [];
                if (carListReportStore.listBranchViewModel) {
                    for (let item of carListReportStore.listBranchViewModel.listBranchResponse.items) {
                        branchOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setBranchOptions(branchOptions);
            }

            await carListReportStore.listCarViewModel.getCarList();
            let carOptions = [];
            if (carListReportStore.listCarViewModel) {
                if(UserContext.info.role !== 5) {
                    for (let item of carListReportStore.listCarViewModel.listCarResponse.items) {
                        carOptions.push(<Option key={item.key} value={item.carNumber}  >{item.carNumber}</Option>);
                    }
                }
                else{
                    const filteredCars = carListReportStore.listCarViewModel.listCarResponse.items.filter(w => w.branchId == UserContext.info.id);
                    for (let item of filteredCars) {
                        carOptions.push(<Option key={item.key} value={item.carNumber}  >{item.carNumber}</Option>);
                    }
                }
            }
            setCarOptions(carOptions);



            
           
         
        }
        catch {

        }
    }

    let viewModel = carListReportStore.getCarListReportViewModel;

    if (!viewModel) return;

    function onUnload() {
        carListReportStore.onCarListReportGetPageUnload();
        setBranchOptions([]);
        setCarOptions([]);
    }

    async function pageIndexChanged(pageIndex, pageSize){
        viewModel.getCarListReportesRequest.pageSize = pageSize;
        viewModel.getCarListReportesRequest.pageIndex = pageIndex - 1;
        await carListReportStore.getCarListReportViewModel.getAllCarListReport(viewModel.getCarListReportesRequest);
    }
    async function pageSizeChanged(current, pageSize){
        viewModel.getCarListReportesRequest.pageSize = pageSize;
        viewModel.getCarListReportesRequest.pageIndex = 0;
        await carListReportStore.getCarListReportViewModel.getAllCarListReport(viewModel.getCarListReportesRequest);
    }
    function onChanged(e){
        viewModel.getCarListReportesRequest[`${e.target.id}`] = e.target.value;
    }
    function onDateChange(date, dateString, prop) {
        viewModel.getCarListReportesRequest[`${prop}`] = dateString;
    }
    async function onFinish(values: any) {
        viewModel.getCarListReportesRequest.pageIndex = 0;
        await viewModel.getAllCarListReport(viewModel.getCarListReportesRequest);
    }
    async function onReset(){
        const pageSize = viewModel.getCarListReportesRequest.pageSize;
        viewModel.getCarListReportesRequest = new GetCarListReportRequest();
        viewModel.getCarListReportesRequest.pageIndex = 0;
        viewModel.getCarListReportesRequest.pageSize = pageSize;
        if(UserContext.info.role == 1){
            carListReportStore.getCarListReportViewModel.getCarListReportesRequest.companyId = UserContext.info.id;
        }
        if(UserContext.info.role == 5){
            carListReportStore.getCarListReportViewModel.getCarListReportesRequest.companyBranchId = UserContext.info.id;
        }

        await viewModel.getAllCarListReport(viewModel.getCarListReportesRequest);
        form.resetFields();
    }
    async function ExportToExcel(){
        viewModel.carListReportExport = [];
        await viewModel.getAllCarListReport(viewModel.getCarListReportesRequest, true);
        if(viewModel.carListReportExport && viewModel?.carListReportExport?.length > 0)
            ExportExcel(columns, viewModel?.carListReportExport, "CarListReport");
    }


    
    function onSelectChanged(e, propName){
        viewModel.getCarListReportesRequest[`${propName}`] = e;

        
        if(propName === "companyId") {
            let carOptions = [];
            const filteredCars = carListReportStore.listCarViewModel.listCarResponse.items.filter(w => w.companyId == e);
            for (let item of filteredCars) {
             
                carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
            }
            setCarOptions(carOptions);

            let branchOptions = [];
            const filteredBranches = carListReportStore.listBranchViewModel.listBranchResponse.items.filter(w => w.companyId == e);
            for (let item of filteredBranches) {
                branchOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
            }
            setBranchOptions(branchOptions);
        }
        if(propName === "companyBranchId") {
            let carOptions = [];
            const filteredCars = carListReportStore.listCarViewModel.listCarResponse.items.filter(w => w.branchId == e);
            for (let item of filteredCars) {
                carOptions.push(<Option key={item.key} value={item.carNumber}  >{item.carNumber}</Option>);
            }
            setCarOptions(carOptions);
        }

        if(propName === "carAutoRecharge") {
            
         if(e=='True')
            e=true;
       
         if(e=='False')
            e=false;
           
         viewModel.getCarListReportesRequest.carAutoRecharge = e;
        }

        if(propName === "carOdometerRecordRequired") {
            
            if(e=='True')
               e=true;
          
            if(e=='False')
               e=false;
              
            viewModel.getCarListReportesRequest.carOdometerRecordRequired = e;
           }
   
           if(propName === "forecastSystem") {
            
            if(e=='True')
               e=true;
          
            if(e=='False')
               e=false;
              
            viewModel.getCarListReportesRequest.forecastSystem = e;
           }



    






    }
    function onSwitchChange(e, propName){
        
        viewModel.getCarListReportesRequest[`${propName}`] = e;
    }

    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={i18next.t("CarListReports.Page.Title")}
                subTitle={i18next.t("CarListReports.Page.SubTitle")}
                extra={[
                    <Button key={"ExportExcel"} type="primary" loading={viewModel?.isProcessing} icon={<FileExcelOutlined />} onClick={ExportToExcel}>
                        {i18next.t("General.Button.ExportExcel")}
                    </Button>
                    ,
                ]}
            />

            <Collapse defaultActiveKey={['1']}className='searchPanelFotamt'>
                <Panel header={i18next.t("General.SearchPanel.Text")}  key="1">
                    <Form {...formItemLayout} layout={"vertical"} onFinish={onFinish} form={form}
                          key={"searchForm"}
                          scrollToFirstError>
                        <Row gutter={[24, 16]}>
                            {/*{UserContext.info.role == 100 ?
                                <Col span={8}>
                                    <Form.Item name="companyId" initialValue={viewModel?.getCarListReportesRequest?.companyId}
                                               key={"companyId"}
                                               label={i18next.t("CarListReportes.SearchPanel.Label.companyName")}>
                                        <Input onChange={onChanged}/>
                                        <Select style={{width: "100%", display:"block"}}
                                                showSearch={true} onChange={(e) => onSelectChanged(e, "companyId")}>
                                            {companyOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>: ""}*/}

                                {UserContext.info.role == 1 ?                          
                                <Col span={5}>
                                <Form.Item name="companyBranchId" initialValue={viewModel?.getCarListReportesRequest?.companyBranchId}
                                           key={"companyBranchId"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyBranchName")}>
                                   
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "companyBranchId")}>
                                        {branchOptions}
                                    </Select>
                                </Form.Item>
                            </Col> 
                                :""}


                            <Col span={5}>
                                <Form.Item name="carIdNumber" initialValue={viewModel?.getCarListReportesRequest?.carIdNumber}
                                           key={"carIdNumber"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.carIdNumber")}>
                                    {/*<Input onChange={onChanged}/>*/}
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "carIdNumber")}>
                                        {carOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

{/*
                            <Col span={8}>

                                <Form.Item name="carId" initialValue={viewModel?.getCarListReportesRequest?.carId}
                                           key={"carId"}
                                           label={i18next.t("CarListReports.SearchPanel.Label.carId")}>
                                 
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "carId")}>
                                        {carOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

*/}

                            <Col span={5}>
                <Form.Item name="consumptionType" initialValue={viewModel?.getCarListReportesRequest?.consumptionType}
                           key={"consumptionType"}
                           label={i18next.t("Cars.Label.consumptionType")}
                          >
                    {/*<Input onChange={onChanged}/>*/}
                    <Select options={consumptionTypeOptions}  allowClear={true} showSearch={true} onChange={(e) => onSelectChanged(e, "consumptionType")} />
                </Form.Item>
                    </Col>


                    <Col span={5}>
                        <Form.Item name="carAutoRecharge" initialValue={viewModel?.getCarListReportesRequest?.carAutoRecharge}
                                   key={"carAutoRecharge"}
                                   label={i18next.t("Cars.Label.carAutoRecharge")}>
                            <Select options={TrueFalseopyion}  allowClear={true} showSearch={true} onChange= {(e) => onSelectChanged(e, 'carAutoRecharge')} />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item name="carOdometerRecordRequired" initialValue={viewModel?.getCarListReportesRequest?.carAutoRecharge}
                                   key={"carOdometerRecordRequired"}
                                   label={i18next.t("Cars.Label.carOdometerRecordRequired")}>
                           <Select options={TrueFalseopyion}  allowClear={true} showSearch={true} onChange= {(e) => onSelectChanged(e, 'carOdometerRecordRequired')} />
                        </Form.Item>
                    </Col>


                    <Col span={5}>
                        <Form.Item name="forecastSystem" initialValue={viewModel?.getCarListReportesRequest?.carAutoRecharge}
                                   key={"forecastSystem"}
                                   label={i18next.t("Cars.Label.carSmartFuel")}>
                           <Select options={TrueFalseopyion}  allowClear={true} showSearch={true} onChange= {(e) => onSelectChanged(e, 'forecastSystem')} />
                        </Form.Item>
                    </Col>
                            
                         
                        </Row>
                        <PageHeader
                            ghost={false}
                            subTitle={<div>
                                {viewModel?.errorMessage &&
                                <Alert message={viewModel?.errorMessage} type="error" />
                                }
                            </div>}
                            extra={[
                                <Button type="primary" loading={viewModel.isProcessing} onClick={onReset} danger key="reset" size={"large"} htmlType="reset">
                                    {i18next.t("General.SearchPanel.ResetButton")}
                                </Button>,
                                <Button type="primary" loading={viewModel.isProcessing} key="submit" size={"large"} htmlType="submit">
                                    {i18next.t("General.SearchPanel.SearchButton")}
                                </Button>
                            ]}
                        />
                    </Form>
                </Panel>
            </Collapse>
            <br/>
            <Table dataSource={viewModel?.carListReportList} columns={columns} loading={viewModel?.isProcessing}
                   bordered={true} pagination={false} sticky />
            <br/>
            <Pagination
                total={viewModel?.totalSize}
                showSizeChanger
                showQuickJumper
                defaultPageSize={20}
                onChange={pageIndexChanged}
                onShowSizeChange={pageSizeChanged}
                showTotal={total => `Total ${total} items`}
            />
        </div>
    )
}));


export default CarListReportList;


