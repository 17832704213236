import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import "./CustomerStatementSummaryList.scss";
import Stores from "app/constants/Stores";

import {
    Button, Collapse, Col, Row,
    Pagination, Input, Form,
    Table, PageHeader, Space, DatePicker, Select
} from "antd";
import {
    FileExcelOutlined,

} from '@ant-design/icons';
import i18next from "i18next";
import GetCustomerStatementRequest from "../../handlers/get/GetCustomerStatementSummaryRequest";
import UserContext from "../../../../identity/contexts/UserContext";
import CustomerStatementColumns from "./CustomerStatementSummaryColumns";

import ExportExcel from "../../../../app/utils/ExportExcel";

import CustomerStatementSummaryStore from '../../stores/CustomerStatementSummaryStore';
import CustomerStatementSummaryColumns from './CustomerStatementSummaryColumns';
import { color } from 'highcharts';
import { values } from 'mobx';
import { render } from 'react-dom';
import renderEmpty from 'antd/lib/config-provider/renderEmpty';
import { renderGaugeStatistic } from '@antv/g2plot/lib/utils';

const { Panel } = Collapse;
const { Option } = Select;

interface CustomerStatementSummaryListProps {
    customerStatementSummaryStore?: CustomerStatementSummaryStore
}

const CustomerStatementSummaryList: React.FC<CustomerStatementSummaryListProps> = inject(Stores.customerStatementSummaryStore)(observer(({customerStatementSummaryStore}) => {
    const [dataFetched, setDataFetched] = React.useState(false);
    const [companyOptions, setCompanyOptions] = React.useState([]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };
    const [form] = Form.useForm();
    CustomerStatementColumns.forEach(w => {
       w.title = i18next.t(w.title);
    });

    const columns: any[] = [...CustomerStatementSummaryColumns ,

        

        {title: i18next.t( "CustomerStatementSummarys.Label.accountDebit"), dataIndex: "accountDebitAmount",
         
            key: "accountDebitAmount", responsive: ['md'],
            render          
            (text, record) { return {
                  props: {
                 style:{ fontSize: '13px',fontWeight:'bold', color:'#ff5050'},
                  
                  },
                  children: <div >{  text.toLocaleString("en")}</div>

                };
                 
            }
                  
        },
            {title: i18next.t( "CustomerStatementSummarys.Label.accountCredit"), dataIndex: "accountCreditAmount",key: "accountCreditAmount", responsive: ['md'],

                render          
                (text, record) { return {
                      props: {
                     style:{ fontSize: '13px',fontWeight:'bold', color:'#0d8bf1'},
                      
                      },
                      children: <div >{  text.toLocaleString("en")}</div>
    
                    };
                     
                }

            },
            {title: i18next.t( "CustomerStatementSummarys.Label.accountBalance"), dataIndex: "accountBalanceAmount", 
                key: "accountBalanceAmount", responsive: ['md'], 
                
                
            
                    render       
                    (text, record) { return  {
                        
                          props: {
                      //   style:{ fontSize: '13px',fontWeight:'bold', color:'green'},
                          
                          },
                          children: <div className='celFormat'>{  text.toLocaleString("en")}</div>
        
                        };
                         
                    }
                }

           
        ];

    useEffect(() => {
        onLoad();

        return onUnload;
    }, []);

    async function onLoad() {
        customerStatementSummaryStore.onCustomerStatementSummaryGetPageLoad();
        customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getCustomerStatementSummarysRequest = new GetCustomerStatementRequest();
        customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getCustomerStatementSummarysRequest.pageSize = 10;
        customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getCustomerStatementSummarysRequest.pageIndex = 0;
        if(UserContext.info.role == 1){
            customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getCustomerStatementSummarysRequest.companyId = UserContext.info.id;
        }

        //  this order to load data diract
       // await customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getAllCustomerStatementSummary(customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getCustomerStatementSummarysRequest);

        try {
            if (UserContext.info.role === 100) {
                await customerStatementSummaryStore.listCompanyViewModel.getCompanyList();
                let companyOptions = [];
                if (customerStatementSummaryStore.listCompanyViewModel) {
                    for (let item of customerStatementSummaryStore.listCompanyViewModel.listCompanyResponse.items) {
                        companyOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setCompanyOptions(companyOptions);
            }
        }
        catch {

        }
        setDataFetched(true);
    }

    let viewModel = customerStatementSummaryStore.getCustomerStatementSummaryViewModel;

    if (!viewModel) return;

    function onUnload() {
        customerStatementSummaryStore.onCustomerStatementSummaryGetPageUnload();
    }

    async function pageIndexChanged(pageIndex, pageSize){
        viewModel.getCustomerStatementSummarysRequest.pageSize = pageSize;
        viewModel.getCustomerStatementSummarysRequest.pageIndex = pageIndex - 1;
        await customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getAllCustomerStatementSummary(viewModel.getCustomerStatementSummarysRequest);
    }
    async function pageSizeChanged(current, pageSize){
        viewModel.getCustomerStatementSummarysRequest.pageSize = pageSize;
        viewModel.getCustomerStatementSummarysRequest.pageIndex = 0;
        await customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getAllCustomerStatementSummary(viewModel.getCustomerStatementSummarysRequest);
    }
    function onChanged(e){
        viewModel.getCustomerStatementSummarysRequest[`${e.target.id}`] = e.target.value;
    }
    function onDateChange(date, dateString, prop) {
        viewModel.getCustomerStatementSummarysRequest[`${prop}`] = dateString;
    }
    async function onFinish(values: any) {
        viewModel.getCustomerStatementSummarysRequest.pageIndex = 0;
        await viewModel.getAllCustomerStatementSummary(viewModel.getCustomerStatementSummarysRequest);
    }
    async function onReset(){
        const pageSize = viewModel.getCustomerStatementSummarysRequest.pageSize;
        viewModel.getCustomerStatementSummarysRequest = new GetCustomerStatementRequest();
        viewModel.getCustomerStatementSummarysRequest.pageIndex = 0;
        viewModel.getCustomerStatementSummarysRequest.pageSize = pageSize;
        if(UserContext.info.role == 1){
           customerStatementSummaryStore.getCustomerStatementSummaryViewModel.getCustomerStatementSummarysRequest.companyId = UserContext.info.id;
        }
        await viewModel.getAllCustomerStatementSummary(viewModel.getCustomerStatementSummarysRequest);
        form.resetFields();
    }
    async function ExportToExcel(){
        viewModel.customerStatementSummaryExport = [];
        await viewModel.getAllCustomerStatementSummary(viewModel.getCustomerStatementSummarysRequest, true);
        if(viewModel.customerStatementSummaryExport && viewModel.customerStatementSummaryExport?.length > 0)
            ExportExcel(columns, viewModel?.customerStatementSummaryExport, "CustomerStatementSummary");
    }
    function onSelectChanged(e, propName){
        viewModel.getCustomerStatementSummarysRequest[`${propName}`] = e;
    }
    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={i18next.t("CustomerStatementSummarys.Page.Title")}
                subTitle={i18next.t("CustomerStatementSummarys.Page.SubTitle")}
                extra={[
                    <Button className={"button-section"} key={"ExportExcel"} type="primary" loading={viewModel?.isProcessing} icon={<FileExcelOutlined />} onClick={ExportToExcel}>
                        {i18next.t("General.Button.ExportExcel")}
                    </Button>
                    ,
                ]}
            />

        <Collapse defaultActiveKey={['1']}className='searchPanelFotamt'>
                <Panel header={i18next.t("General.SearchPanel.Text")}  key="1">
                    <Form {...formItemLayout} layout={"vertical"} onFinish={onFinish} form={form}
                          key={"searchForm"}
                          scrollToFirstError>
                        <Row gutter={[24, 16]}>
                            {UserContext.info.role == 100 ?
                                <Col span={8}>
                                    <Form.Item name="companyId" initialValue={viewModel?.getCustomerStatementSummarysRequest?.companyId}
                                               key={"companyId"}
                                               label={i18next.t("CustomerStatementSummarys.SearchPanel.Label.companyId")}>
                                        <Select style={{width: "100%", display:"block"}} allowClear={true}
                                                showSearch={true} onChange={(e) => onSelectChanged(e, "companyId")}>
                                            {companyOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>: ""}
                            <Col span={8}>
                                <Form.Item name="dateFrom" initialValue={viewModel?.getCustomerStatementSummarysRequest?.dateFrom}
                                           key={"dateFrom"}
                                           label={i18next.t("CustomerStatementSummarys.SearchPanel.Label.dateFrom")}>
                                    <DatePicker format={"YYYY/MM/DD"} onChange={((date, dateString) => onDateChange(date, dateString, "dateFrom"))} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="dateTo" initialValue={viewModel?.getCustomerStatementSummarysRequest?.dateTo}
                                           key={"dateTo"}
                                           label={i18next.t("CustomerStatementSummarys.SearchPanel.Label.dateTo")}>
                                    <DatePicker format={"YYYY/MM/DD"} onChange={((date, dateString) => onDateChange(date, dateString, "dateTo"))} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <PageHeader
                            ghost={false}
                            extra={[
                                <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} onClick={onReset} danger key="reset" size={"large"} htmlType="reset">
                                    {i18next.t("General.SearchPanel.ResetButton")}
                                </Button>,
                                <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} key="submit" size={"large"} htmlType="submit">
                                    {i18next.t("General.SearchPanel.SearchButton")}
                                </Button>
                            ]}
                        />
                    </Form>
                </Panel>
            </Collapse>
            <br/>
            <Table dataSource={viewModel?.customerStatementSummaryList} columns={columns} loading={viewModel?.isProcessing} 
  
    
   
  

                   bordered={true} pagination={false} scroll={{ x: 1000 }} sticky
                 
                    
                  
                   summary={() => (
                       <Table.Summary.Row className='totalTabl'>
                           <Table.Summary.Cell  index={0}>{i18next.t("CustomerStatementSummarys.Label.totalBalance")}</Table.Summary.Cell>
                           <Table.Summary.Cell colSpan={4} index={0}></Table.Summary.Cell>
                           <Table.Summary.Cell   index={0}>{viewModel?.sumCustomerStatementSummary?.toLocaleString() }</Table.Summary.Cell>
                          
                       </Table.Summary.Row>
                   )}/>
            <br/>
            <Pagination
                total={viewModel?.totalSize}
                showSizeChanger
                showQuickJumper
                defaultPageSize={20}
                onChange={pageIndexChanged}
                onShowSizeChange={pageSizeChanged}
                showTotal={total => `Total ${total} items`}
            />
        </div>
    )
}));


export default CustomerStatementSummaryList;


