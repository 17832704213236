import AuthStore from "auth/stores/AuthStore";
import PageStore from "page/stores/PageStore";
import AdminStore from "admin/stores/AdminStore";
import CompaniesStore from "entities/companies/stores/CompaniesStore";
import CustomerStore from "../../customer/stores/CustomerStore";
import SupplierStore from "../../supplier/stores/SupplierStore";
import BundlesStore from "../../entities/bundles/stores/BundlesStore";
import BranchStore from "../../entities/branches/stores/BranchStore";
import PetroStationStore from "../../entities/petro-stations/stores/PetroStationStore";
import StationUserStore from "../../entities/station-users/stores/StationUserStore";
import CarStore from "../../entities/cars/stores/CarStore";
import RechargeBalanceStore from "../../entities/recharge-balances/stores/RechargeBalanceStore";
import SubscriptionStore from "../../entities/Subscriptions/stores/SubscriptionStore";
import InvoiceSummaryStore from "../../reports/InvoiceSummaries/stores/InvoiceSummaryStore";
import InvoiceDetailStore from "../../reports/InvoiceDetails/stores/InvoiceDetailStore";
import CarBalanceStore from "../../reports/CarBalances/stores/CarBalanceStore";
import StationReportStore from "../../reports/StationReports/stores/StationReportStore";
import StationSaleStore from "../../reports/StationSales/stores/StationSaleStore";
import StationStatementStore from "../../reports/StationStatements/stores/StationStatementStore";
import CarTransactionStore from "../../reports/CarTransactions/stores/CarTransactionStore";
import AccountBalanceStore from "../../reports/AccountBalances/stores/AccountBalanceStore";
import PetrolStationListStore from "../../reports/PetrolStationLists/stores/PetrolStationListStore";
import TransferBalanceStore from "../../entities/transfer-balances/stores/TransferBalanceStore";
import PetropayAccountStore from "../../entities/PetropayAccounts/stores/PetropayAccountStore";
import PromotionCouponStore from "../../entities/promotion-coupons/stores/PromotionCouponStore";
import CarConsumptionRateStore from "../../reports/CarConsumptionRates/stores/CarConsumptionRateStore";
import CarKmConsumptionStore from "../../reports/CarKmConsumptions/stores/CarKmConsumptionStore";
import CarOdometerMaxStore from "../../reports/CarOdometerMaxes/stores/CarOdometerMaxStore";
import CarOdometerMinStore from "../../reports/CarOdometerMins/stores/CarOdometerMinStore";
import OdometerBetweenDateStore from "../../reports/OdometerBetweenDates/stores/OdometerBetweenDateStore";
import AppSettingStore from "../../entities/app-settings/stores/AppSettingStore";
import OdometerRecordStore from "../../entities/odometer-records/stores/OdometerRecordStore";
import MenuStore from "../../entities/menus/stores/MenuStore";
import EmployeeStore from "../../entities/employees/stores/EmployeeStore";
import OdometerHistoryStore from "../../reports/OdometerHistories/stores/OdometerHistoryStore";
import NewCustomerStore from "../../entities/new-customers/stores/NewCustomerStore";
import TransferBonusStore from "../../entities/TransferBonuses/stores/TransferBonusStore";
import CustomerStatementStore from "../../reports/CustomerStatements/stores/CustomerStatementStore";
import CompanyBranchStatementStore from "../../reports/CompanyBranchStatements/stores/CompanyBranchStatementStore";
import PetrolCompaniesStore from "../../entities/petrol-companies/stores/PetrolCompaniesStore";
import OilPricesStore from "entities/OliPrice/stores/OilPricesStore";
import InvoiceReviewStore from "reports/InvoiceReview/stores/InvoiceReviewStore";
import CarUserStore from "entities/CarUser/stores/CarUserStore";
import InvoiceSummaryByCarStore from "reports/InvoiceSummariesByCar/stores/InvoiceSummaryByCarStore";
import InvoiceSummaryByBranchStore from "reports/InvoiceSummariesByBranch/stores/InvoiceSummaryByBranchStore";
import CarListReportStore from "reports/CarListReports/stores/CarListReportStore";
import CustomerStatementSummaryStore from "reports/CustomerStatementSummary/stores/CustomerStatementSummaryStore";




export class AppStore
{
    auth: AuthStore;
    page: PageStore;
    customer: CustomerStore;
    admin: AdminStore;
    supplier: SupplierStore;
    companies: CompaniesStore;
    petrolCompanies: PetrolCompaniesStore;
    bundles: BundlesStore;
    branch: BranchStore;
    petroStation: PetroStationStore;
    stationUser: StationUserStore;
    carUser: CarUserStore;
    car: CarStore;
    rechargeBalance: RechargeBalanceStore;
    subscription: SubscriptionStore;
   
    invoiceSummary: InvoiceSummaryStore;
    invoiceSummaryByCar: InvoiceSummaryByCarStore;
    invoiceSummaryByBranch: InvoiceSummaryByBranchStore;
    invoiceReview: InvoiceReviewStore;
    invoiceDetail: InvoiceDetailStore;
    carBalance: CarBalanceStore;
    stationReport: StationReportStore;
    stationSale: StationSaleStore;
    stationStatement: StationStatementStore;
    carTransaction: CarTransactionStore;
    accountBalance: AccountBalanceStore;
    petrolStationList: PetrolStationListStore;
    transferBalance: TransferBalanceStore;
    petropayAccount: PetropayAccountStore;
    promotionCoupon: PromotionCouponStore;
    carConsumptionRate: CarConsumptionRateStore;
    carKmConsumption: CarKmConsumptionStore;
    carOdometerMax: CarOdometerMaxStore;
    carListReport: CarListReportStore;
    carOdometerMin: CarOdometerMinStore;
    odometerBetweenDate: OdometerBetweenDateStore;
    appSetting: AppSettingStore;
    odometerRecord: OdometerRecordStore;
    menu: MenuStore;
    employee: EmployeeStore;
    odometerHistory: OdometerHistoryStore;
    newCustomer: NewCustomerStore;
    transferBonus: TransferBonusStore;
    customerStatement: CustomerStatementStore;
    customerStatementSummary: CustomerStatementSummaryStore;
    companyBranchStatement: CompanyBranchStatementStore;
    oilPrices: OilPricesStore;
   
    

    constructor()
    {
        this.auth = new AuthStore(this);
        this.page = new PageStore(this);
        this.customer = new CustomerStore(this);
        this.admin = new AdminStore(this);
        this.supplier = new SupplierStore(this);
        this.companies = new CompaniesStore(this);
        this.petrolCompanies = new PetrolCompaniesStore(this);
        this.bundles = new BundlesStore(this);
        this.branch = new BranchStore(this);
        this.petroStation = new PetroStationStore(this);
        this.stationUser = new StationUserStore(this);
        this.carUser= new CarUserStore(this);
        this.car = new CarStore(this);
        this.rechargeBalance = new RechargeBalanceStore(this);
        this.subscription = new SubscriptionStore(this);
       
        this.invoiceSummary = new InvoiceSummaryStore(this);
        this.invoiceSummaryByCar = new InvoiceSummaryByCarStore(this);
        this.invoiceSummaryByBranch = new InvoiceSummaryByBranchStore(this);
        this.invoiceReview = new InvoiceReviewStore(this);
        this.invoiceDetail = new InvoiceDetailStore(this);
        this.carBalance = new CarBalanceStore(this);
        this.stationReport = new StationReportStore(this);
        this.stationSale = new StationSaleStore(this);
        this.stationStatement = new StationStatementStore(this);
        this.carTransaction = new CarTransactionStore(this);
        this.accountBalance = new AccountBalanceStore(this);
        this.petrolStationList = new PetrolStationListStore(this);
        this.transferBalance = new TransferBalanceStore(this);
        this.petropayAccount = new PetropayAccountStore(this);
        this.promotionCoupon = new PromotionCouponStore(this);
        this.carConsumptionRate = new CarConsumptionRateStore(this);
        this.carKmConsumption = new CarKmConsumptionStore(this);
        this.carOdometerMax = new CarOdometerMaxStore(this);
        this.carListReport = new CarListReportStore(this);
        this.carOdometerMin = new CarOdometerMinStore(this);
        this.odometerBetweenDate = new OdometerBetweenDateStore(this);
        this.appSetting = new AppSettingStore(this);
        this.odometerRecord = new OdometerRecordStore(this);
        this.menu = new MenuStore(this);
        this.employee = new EmployeeStore(this);
        this.odometerHistory = new OdometerHistoryStore(this);
        this.newCustomer = new NewCustomerStore(this);
        this.transferBonus = new TransferBonusStore(this);
        this.customerStatement = new CustomerStatementStore(this);
        this.customerStatementSummary = new CustomerStatementSummaryStore(this);
        this.companyBranchStatement = new CompanyBranchStatementStore(this);
        this.oilPrices=new OilPricesStore(this);
        
    }
}
