export default
[
    /*{title: "CustomerStatementSummarys.Label.key", dataIndex: "key", key: "key", responsive: ['md']},
    
     {title: "CustomerStatementSummarys.Label.accountId", dataIndex: "accountId", key: "accountId", responsive: ['md']},
     {title: "CustomerStatementSummarys.Label.accountName", dataIndex: "accountName", key: "accountName", responsive: ['md']}

      {title: "CustomerStatementSummarys.Label.sumTransAmount", dataIndex: "sumTransAmount", key: "sumTransAmount", responsive: ['md']},
       {title: "CustomerStatementSummarys.Label.companyId", dataIndex: "companyId", key: "companyId",  responsive: ['md']},

    */


    
     {title: "CustomerStatementSummarys.Label.companyName", dataIndex: "companyName", key: "companyName", responsive: ['md']},
    {title: "CustomerStatementSummarys.Label.transactionDataTime", dataIndex: "transactionDataTime", key: "transactionDataTime", responsive: ['md']},
   
    {title: "CustomerStatementSummarys.Label.transDocument", dataIndex: "transDocument", key: "transDocument", responsive: ['md']},
  
   
   
   
]
