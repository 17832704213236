import {makeAutoObservable} from "mobx";

export default class AddOilPriceRequest
{
   
   // productID: number;
    productDescriptionEN: string;
    productDescriptionAR: string;
    productCostPrice: number;
    productSelingPrice: number;
    productLiter: number;
    petrol_Company_id : number;
    product_Active : boolean;
    serviceId: number;

    constructor(
    ) {
        makeAutoObservable(this);
    }
}
