import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import { Link } from "react-router-dom";
import "./InvoiceSummaryByCarList.scss";
import Stores from "app/constants/Stores";

import {
    Button, Collapse, Col, Row,
    Pagination, Input, Form,
    Table, PageHeader, Space, DatePicker, Select, Alert, Spin
} from "antd";
import {
    FolderViewOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
import i18next from "i18next";
import GetInvoiceSummaryByCarRequest from "../../handlers/get/GetInvoiceSummaryByCarRequest";
import UserContext from "../../../../identity/contexts/UserContext";
import InvoiceSummaryByCarColumns from "./InvoiceSummaryByCarColumns";
import InvoiceSummaryByCarStore from "../../stores/InvoiceSummaryByCarStore";

import ExportExcel from "../../../../app/utils/ExportExcel";
import Constants from "../../../../app/constants/Constants";
import { line } from '@antv/g2plot';
import { color } from 'highcharts';

const { Panel } = Collapse;
const { Option } = Select;

interface InvoiceSummaryByCarListProps {
    invoiceSummaryByCarStore?: InvoiceSummaryByCarStore
    
    
}

const InvoiceSummaryByCarList: React.FC<InvoiceSummaryByCarListProps> = inject(Stores.invoiceSummaryByCarStore)(observer(({invoiceSummaryByCarStore}) => {
    const [dataFetched, setDataFetched] = React.useState(false);
    const [carOptions, setCarOptions] = React.useState([]);
    
    const [branchOptions, setBranchOptions] = React.useState([]);
    const [companyOptions, setCompanyOptions] = React.useState([]);
    const [serviceMasterOptions, setServiceMasterOptions] = React.useState([]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };
    const [form] = Form.useForm();
    InvoiceSummaryByCarColumns.forEach(w => {
       w.title = i18next.t(w.title);
    });

   const companyColumn = {title: i18next.t("InvoiceSummaries.Label.companyName"), dataIndex: "companyName", key: "companyName", responsive: ['md']};
    const columns: any[] = [...InvoiceSummaryByCarColumns,,];

    if(UserContext.info.role == 100)
    {
       columns.unshift(companyColumn);
    }

    useEffect(() => {
        onLoad();

        return onUnload;
    }, []);

    async function onLoad() {
        invoiceSummaryByCarStore.onInvoiceSummaryByCarGetPageLoad();
        invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getInvoiceSummariesByCarRequest = new GetInvoiceSummaryByCarRequest();
        invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getInvoiceSummariesByCarRequest.pageSize = 20;
        invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getInvoiceSummariesByCarRequest.pageIndex = 0;
        invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getInvoiceSummariesByCarRequest.companyId = UserContext.info.role === 1 ? UserContext.info.id : undefined;
        invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getInvoiceSummariesByCarRequest.companyBranchId = UserContext.info.role === 5 ? UserContext.info.id : undefined;


        try {
            if(UserContext.info.role === 100) {
                await invoiceSummaryByCarStore.listCompanyViewModel.getCompanyList();
                let companyOptions = [];
                if (invoiceSummaryByCarStore.listCompanyViewModel) {
                    for (let item of invoiceSummaryByCarStore.listCompanyViewModel.listCompanyResponse.items) {
                        companyOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setCompanyOptions(companyOptions);
            }
            if(UserContext.info.role === 1) {
                await invoiceSummaryByCarStore.listBranchViewModel.getBranchList();
                let branchOptions = [];
                if (invoiceSummaryByCarStore.listBranchViewModel) {
                    for (let item of invoiceSummaryByCarStore.listBranchViewModel.listBranchResponse.items) {
                        branchOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setBranchOptions(branchOptions);
            }

            await invoiceSummaryByCarStore.listCarViewModel.getCarList();
            let carOptions = [];
            if (invoiceSummaryByCarStore.listCarViewModel) {
                if(UserContext.info.role !== 5) {
                    for (let item of invoiceSummaryByCarStore.listCarViewModel.listCarResponse.items) {
                        carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
                    }
                }
                else{
                    const filteredCars = invoiceSummaryByCarStore.listCarViewModel.listCarResponse.items.filter(w => w.branchId == UserContext.info.id);
                    for (let item of filteredCars) {
                        carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
                    }
                }
            }
            setCarOptions(carOptions);
           






            ///////////
            await invoiceSummaryByCarStore.listServiceMasterViewModel.getServiceMasterList();
            let serviceMasterOptions = [];
            if (invoiceSummaryByCarStore.listServiceMasterViewModel) {
                for (let item of invoiceSummaryByCarStore.listServiceMasterViewModel.listServiceMasterResponse.items) {
                    serviceMasterOptions.push(<Option key={item.key} value={item.title}>{item.title}</Option>);
                }
            }
            setServiceMasterOptions(serviceMasterOptions);

            setDataFetched(true);
         //   await invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getAllInvoiceSummaryByCar(invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getInvoiceSummariesByCarRequest);
        }
        catch {

        }
    }

    let viewModel = invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel;

    if (!viewModel) return;

    function onUnload() {
        invoiceSummaryByCarStore.onInvoiceSummaryByCarGetPageUnload();
        setServiceMasterOptions([]);
        setCompanyOptions([]);
        setBranchOptions([]);
        setCarOptions([]);
       
    }

    async function pageIndexChanged(pageIndex, pageSize){
        viewModel.getInvoiceSummariesByCarRequest.pageSize = pageSize;
        viewModel.getInvoiceSummariesByCarRequest.pageIndex = pageIndex - 1;
        await invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getAllInvoiceSummaryByCar(viewModel.getInvoiceSummariesByCarRequest);
    }
    async function pageSizeChanged(current, pageSize){
        viewModel.getInvoiceSummariesByCarRequest.pageSize = pageSize;
        viewModel.getInvoiceSummariesByCarRequest.pageIndex = 0;
        await invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getAllInvoiceSummaryByCar(viewModel.getInvoiceSummariesByCarRequest);
    }
    function onChanged(e){
        viewModel.getInvoiceSummariesByCarRequest[`${e.target.id}`] = e.target.value;
    }

    function onSelectChanged(e, propName){
        
        viewModel.getInvoiceSummariesByCarRequest[`${propName}`] = e;
        if(propName === "companyId") {
            let carOptions = [];
            const filteredCars = invoiceSummaryByCarStore.listCarViewModel.listCarResponse.items.filter(w => w.companyId == e);
            for (let item of filteredCars) {
                carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
            }
            setCarOptions(carOptions);

            let branchOptions = [];
            const filteredBranches = invoiceSummaryByCarStore.listBranchViewModel.listBranchResponse.items.filter(w => w.companyId == e);
            for (let item of filteredBranches) {
                branchOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
            }
            setBranchOptions(branchOptions);
        }
        if(propName === "companyBranchId") {
            let carOptions = [];
            const filteredCars = invoiceSummaryByCarStore.listCarViewModel.listCarResponse.items.filter(w => w.branchId == e);
            for (let item of filteredCars) {
                carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
            }
            setCarOptions(carOptions);
        }

        
    }
    function onDateChange(date, dateString, prop) {
        viewModel.getInvoiceSummariesByCarRequest[`${prop}`] = dateString;
    }
    async function onFinish(values: any) {
        viewModel.getInvoiceSummariesByCarRequest.pageIndex = 0;
        await viewModel.getAllInvoiceSummaryByCar(viewModel.getInvoiceSummariesByCarRequest);
    }
    async function onReset(){
        const pageSize = viewModel.getInvoiceSummariesByCarRequest.pageSize;
        viewModel.getInvoiceSummariesByCarRequest = new GetInvoiceSummaryByCarRequest();
        viewModel.getInvoiceSummariesByCarRequest.pageIndex = 0;
        viewModel.getInvoiceSummariesByCarRequest.pageSize = pageSize;
        if(UserContext.info.role == 1){
            invoiceSummaryByCarStore.getInvoiceSummaryByCarViewModel.getInvoiceSummariesByCarRequest.companyId = UserContext.info.id;
        }
        await viewModel.getAllInvoiceSummaryByCar(viewModel.getInvoiceSummariesByCarRequest);
        form.resetFields();
    }
    async function ExportToExcel(){
        viewModel.invoiceSummaryByCarExport = [];
        await viewModel.getAllInvoiceSummaryByCar(viewModel.getInvoiceSummariesByCarRequest, true);
        if(viewModel.invoiceSummaryByCarExport && viewModel.invoiceSummaryByCarExport?.length > 0) {
            columns.pop();
            ExportExcel(columns, viewModel?.invoiceSummaryByCarExport, "InvoiceSummaryByCar");
        }
    }

    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={i18next.t("InvoiceSummariesByCar.Page.Title")}
                subTitle={i18next.t("InvoiceSummariesByCar.Page.SubTitle")}
                extra={[
                    <Button className={"button-section"} key={"ExportExcel"} type="primary" loading={viewModel?.isProcessing} icon={<FileExcelOutlined />} onClick={ExportToExcel}>
                        {i18next.t("General.Button.ExportExcel")}
                    </Button>
                    ,
                ]}
            />

            <Collapse defaultActiveKey={['1']}className='searchPanelFotamt'>
                <Panel header={i18next.t("General.SearchPanel.Text")}  key="1">
                    <Form {...formItemLayout} layout={"vertical"} onFinish={onFinish} form={form}
                          key={"searchForm"}
                          scrollToFirstError>
                        <div>
                        {dataFetched ?
                        <Row gutter={[24, 16]}>
                            {UserContext.info.role === 100 ?
                                <Col span={8}>
                                    <Form.Item name="companyId" initialValue={viewModel?.getInvoiceSummariesByCarRequest?.companyId}
                                               key={"companyId"}
                                               label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyName")}>
                                        {/*<Input onChange={onChanged}/>*/}
                                        <Select style={{width: "100%", display:"block"}} allowClear={true}
                                                showSearch={true} onChange={(e) => onSelectChanged(e, "companyId")}>
                                            {companyOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>: ""}
                            {/*<Col span={8}>
                                <Form.Item name="companyBranchName" initialValue={viewModel?.getInvoiceSummariesRequest?.companyBranchName}
                                           key={"companyBranchName"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyBranchName")}>
                                    <Input onChange={onChanged}/>
                                    <Select style={{width: "100%", display:"block"}}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "companyBranchName")}>
                                        {petropayAccountOptions}
                                    </Select>
                                </Form.Item>
                            </Col>*/}
                            {UserContext.info.role === 1 ?
                            <Col span={8}>
                                <Form.Item name="companyBranchId" initialValue={viewModel?.getInvoiceSummariesByCarRequest?.companyBranchId}
                                           key={"companyBranchId"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyBranchName")}>
                                    {/*<Input onChange={onChanged} />*/}
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "companyBranchId")}>
                                        {branchOptions}
                                    </Select>
                                </Form.Item>
                            </Col> : "" }
                            <Col span={8}>
                                <Form.Item name="carIdNumber" initialValue={viewModel?.getInvoiceSummariesByCarRequest?.carIdNumber}
                                           key={"carIdNumber"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.carIdNumber")}>
                                    {/*<Input onChange={onChanged}/>*/}
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "carIdNumber")}>
                                        {carOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                            


                            <Col span={8}>
                                <Form.Item name="serviceDescription" initialValue={viewModel?.getInvoiceSummariesByCarRequest?.serviceDescription}
                                           key={"serviceDescription"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.serviceDescription")}>
                                    {/*<Input onChange={onChanged}/>*/}
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "serviceDescription")}>
                                        {serviceMasterOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="invoiceDataTimeFrom" initialValue={viewModel?.getInvoiceSummariesByCarRequest?.invoiceDataTimeFrom}
                                           key={"invoiceDataTimeFrom"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.invoiceDataTimeFrom")}>
                                    <DatePicker format={Constants.dateFormat} onChange={((date, dateString) => onDateChange(date, dateString, "invoiceDataTimeFrom"))} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="invoiceDataTimeTo" initialValue={viewModel?.getInvoiceSummariesByCarRequest?.invoiceDataTimeTo}
                                           key={"invoiceDataTimeTo"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.invoiceDataTimeTo")}>
                                    <DatePicker format={Constants.dateFormat} onChange={((date, dateString) => onDateChange(date, dateString, "invoiceDataTimeTo"))} />
                                </Form.Item>
                            </Col>
                                </Row>
                                    :
                                    <Row gutter={[24, 16]}>
                                        <Col offset={11} span={8}>
                                            <Spin className={"spine"} size="large" />
                                        </Col>
                                    </Row>}
                            </div>
                        <PageHeader
                            ghost={false}
                            subTitle={<div>
                                {viewModel?.errorMessage &&
                                <Alert message={viewModel?.errorMessage} type="error" />
                                }
                            </div>}
                            extra={[
                                <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} onClick={onReset} danger key="reset" size={"large"} htmlType="reset">
                                    {i18next.t("General.SearchPanel.ResetButton")}
                                </Button>,
                                <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} key="submit" size={"large"} htmlType="submit">
                                    {i18next.t("General.SearchPanel.SearchButton")}
                                </Button>
                            ]}
                        />
                    </Form>
                </Panel>
            </Collapse>
            <br/>
            <Table dataSource={viewModel?.invoiceSummaryByCarList} columns={columns} loading={viewModel?.isProcessing}
                   bordered={true} pagination={false} //scroll={{ x: 1500 }} sticky 
                   summary={() => (
                       <Table.Summary.Row className='totalTabl'>
                           <Table.Summary.Cell colSpan= {1} index={0}>{i18next.t("General.Table.Total")}</Table.Summary.Cell>
                           <Table.Summary.Cell colSpan={UserContext.info.role == 1 ? 1 : 2} index={1}></Table.Summary.Cell>
                           <Table.Summary.Cell index={1}>{viewModel?.sumInvoiceFuelConsumptionLiter?.toLocaleString()}</Table.Summary.Cell>
                           <Table.Summary.Cell index={1}>{viewModel?.sumInvoiceAmount?.toLocaleString()}</Table.Summary.Cell>
                           <Table.Summary.Cell index={1}>{viewModel?.sumServiceFeesAmount?.toLocaleString()}</Table.Summary.Cell>
                           <Table.Summary.Cell index={1}>{viewModel?.sumTotalInvoiceAndFees?.toLocaleString()}</Table.Summary.Cell>
                           <Table.Summary.Cell index={1}>{viewModel?.sumConsumptionValue?.toLocaleString()}</Table.Summary.Cell>

                          {/* <Table.Summary.Cell colSpan={1} index={0}></Table.Summary.Cell>*/}
                       </Table.Summary.Row>
                   )} />
            <br/>
            <Pagination
                total={viewModel?.totalSize}
                showSizeChanger
                showQuickJumper
                defaultPageSize={20}
                onChange={pageIndexChanged}
                onShowSizeChange={pageSizeChanged}
                showTotal={total => `Total ${total} items`}
            />
        </div>
    )
}));


export default InvoiceSummaryByCarList;


