import {makeAutoObservable} from "mobx";
import i18next from "i18next";
import log from "loglevel";
import {message} from "antd";
import GetCustomerRequest from "../handlers/get/GetCustomerRequest";
import GetCustomerHandler from "../handlers/get/GetCustomerHandler";
import CustomerStore from "../stores/CustomerStore";
import CompanyBranchItem from "../handlers/get/CompanyBranchItem";
import CompanySubscriptionItem from "../handlers/get/CompanySubscriptionItem";
import {getLocalizedString} from "../../app/utils/Localization";
import CompanyMonthlyInvoiceItem from "customer/handlers/get/CompanyMonthlyInvoiceItem";
import CompanyBranchActiveAndInActiveCarItem from "customer/handlers/get/CompanyBranchActiveAndInActiveCarItem";
import CompanyBranchMonthlyInvoicesItem from "customer/handlers/get/CompanyBranchMonthlyInvoicesItem";
import CompanyInvoiceAlartItem from "customer/handlers/get/CompanyInvoiceAlartItem";




export default class GetCustomerViewModel {
    isProcessing: boolean;
    errorMessage: string;

    getCustomerRequest: GetCustomerRequest;

    companyBranchItems: CompanyBranchItem[];
    companySubscriptionItems: CompanySubscriptionItem[];
    companyMonthlyInvoiceItems:CompanyMonthlyInvoiceItem[];
    companyBranchActiveAndInActiveCarItems:CompanyBranchActiveAndInActiveCarItem[];
    companyBranchMonthlyInvoicesItems:CompanyBranchMonthlyInvoicesItem[];
    companyInvoiceAlartItems:CompanyInvoiceAlartItem[];
    
    totalCustomerBalance: number;
    totalCarBalance: number;
    totalBranchBalance: number;

    totalCompanyActiveCar:number;
    totalCompanyInActiveCar:number;
    totalBranchOdometerOffCarCount:number;
   
    
    totalCompanyCarHasConsumptionAlart:number;
    totalSubscriptionNearExpiry :number;
  
   

    constructor(public customerStore: CustomerStore) {
        makeAutoObservable(this);

    }

    public async getDashboardData(getCustomerRequest: GetCustomerRequest) {
        try {
            this.isProcessing = true;
            let response = await GetCustomerHandler.get(getCustomerRequest);

            if (response && response.success) {
                let result = response.data;
                this.companyBranchItems = [];
                this.companyBranchItems = result.companyBranchItems;
                this.companySubscriptionItems = [];
                this.companySubscriptionItems = result.companySubscriptionItems;
                this.totalCarBalance = result.totalCarBalance;
                this.totalBranchBalance = result.totalBranchBalance;
                this.totalCustomerBalance = result.totalCustomerBalance;
                this.companyMonthlyInvoiceItems = result.companyMonthlyInvoiceItems;
                this.companyBranchActiveAndInActiveCarItems = result.companyBranchActiveAndInActiveCarItems;
                this.companyBranchMonthlyInvoicesItems = result.companyBranchMonthlyInvoicesItems;
                this.companyInvoiceAlartItems=result.companyInvoiceAlartItems;
                this.totalCompanyCarHasConsumptionAlart=result.totalCompanyCarHasConsumptionAlart;
                this.totalSubscriptionNearExpiry=result.totalSubscriptionNearExpiry;
                this.totalBranchOdometerOffCarCount=result.totalBranchOdometerOffCarCount;
               
                             

                this.totalCompanyActiveCar = result.totalCompanyActiveCar;
                this.totalCompanyInActiveCar = result.totalCompanyInActiveCar;
                
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('Customers.Error.Get.Message');
            log.error(e);
        } finally {
            this.isProcessing = false;
        }
    }
}
