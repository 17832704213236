// eslint-disable-next-line import/no-anonymous-default-export
export default
{
    apiAuthLogin: "/api/auth/login",
    apiAuthResetPassword: "/api/auth/reset-password",
    apiAuthValidateResetPasswordToken: "/api/auth/validate-reset-password-token",
    apiAuthChangePassword: "/api/auth/change-password",
    apiAuthChangeUserPassword: "/api/auth/change-user-password",
    apiAuthLogout: "/api/auth/logout",
    apiAuthCheck: "/api/auth/check",
    apiAuthRefreshAccessToken: "/api/auth/refresh-access-token",
    apiUserInfo: "/api/user/info",

    apiCompanyAdd: "/api/company/add",
    apiCompanyEdit: "/api/company/edit",
    apiCompanyGet: "/api/company/get",
    apiCompanyDetail: "/api/company/detail",
    apiCompanyDelete: "/api/company/delete",
    apiCompanyList: "/api/company/list",

    apiPetrolCompanyAdd: "/api/petrol-company/add",
    apiPetrolCompanyEdit: "/api/petrol-company/edit",
    apiPetrolCompanyGet: "/api/petrol-company/get",
    apiPetrolCompanyDetail: "/api/petrol-company/detail",
    apiPetrolCompanyDelete: "/api/petrol-company/delete",
    apiPetrolCompanyList: "/api/petrol-company/list",

    apiPetroStationAdd: "/api/petro-station/add",
    apiPetroStationEdit: "/api/petro-station/edit",
    apiPetroStationGet: "/api/petro-station/get",
    apiPetroStationDetail: "/api/petro-station/detail",
    apiPetroStationDelete: "/api/petro-station/delete",
    apiPetroStationPayment: "/api/petro-station/payment",
    apiPetroStationList: "/api/petro-station/list",

    apiStationUserAdd: "/api/station-user/add",
    apiStationUserEdit: "/api/station-user/edit",
    apiStationUserGet: "/api/station-user/get",
    apiStationUserDetail: "/api/station-user/detail",
    apiStationUserDelete: "/api/station-user/delete",
    apiStationUserList: "/api/station-user/list",

    
    apiCarUserAdd: "/api/car-user/add",
    apiCarUserEdit: "/api/car-user/edit",
    apiCarUserGet: "/api/car-user/get",
    apiCarUserDetail: "/api/car-user/detail",
    apiCarUserDelete: "/api/car-user/delete",
    apiCarUserList: "/api/car-user/list",




    apiBranchAdd: "/api/branch/add",
    apiBranchEdit: "/api/branch/edit",
    apiBranchGet: "/api/branch/get",
    apiBranchDetail: "/api/branch/detail",
    apiBranchDelete: "/api/branch/delete",
    apiBranchActive: "/api/branch/active",
    apiBranchList: "/api/branch/list",
    apiBranchChargeBalance: "/api/branch/charge-balance",

    apiCarAdd: "/api/car/add",
    apiCarEdit: "/api/car/edit",
    apiCarGet: "/api/car/get",
    apiCarDetail: "/api/car/detail",
    apiCarDelete: "/api/car/delete",
    apiCarList: "/api/car/list",
    apiCarActive: "/api/car/active",

    apiBundleAdd: "/api/bundle/add",
    apiBundleEdit: "/api/bundle/edit",
    apiBundleGet: "/api/bundle/get",
    apiBundleDetail: "/api/bundle/detail",
    apiBundleDelete: "/api/bundle/delete",


    apiOilPriceAdd: "/api/oilprice/add",
    apiOilPriceEdit: "/api/oilprice/edit",
    apiOilPriceGet: "/api/oilprice/get",
    apiOilPriceDetail: "/api/oilprice/detail",
    apiOilPriceDelete: "/api/oilprice/delete",


    apiRechargeBalanceAdd: "/api/recharge-balance/add",
    apiRechargeBalanceEdit: "/api/recharge-balance/edit",
    apiRechargeBalanceGet: "/api/recharge-balance/get",
    apiRechargeBalanceDetail: "/api/recharge-balance/detail",
    apiRechargeBalanceDelete: "/api/recharge-balance/delete",
    apiRechargeBalanceConfirm: "/api/recharge-balance/confirm",

    apiSubscriptionAdd: "/api/subscription/add",
    apiSubscriptionEdit: "/api/subscription/edit",
    apiSubscriptionGet: "/api/subscription/get",
    apiSubscriptionDetail: "/api/subscription/detail",
    apiSubscriptionDelete: "/api/subscription/delete",
    apiSubscriptionActive: "/api/subscription/active",
    apiSubscriptionCalculate: "/api/subscription/calculate",
    apiSubscriptionCarAdd: "/api/subscription/car-add",
    apiSubscriptionInvoice: "/api/subscription/invoice",
    apiSubscriptionReject: "/api/subscription/reject",


    apiSubscriptionNFCAdd: "/api/subscription/add",
    apiSubscriptionNFCEdit: "/api/subscription/edit",
    apiSubscriptionNFCGet: "/api/subscription/get",
    apiSubscriptionNFCDetail: "/api/subscription/detail",
    apiSubscriptionNFCDelete: "/api/subscription/delete",
    apiSubscriptionNFCActive: "/api/subscription/active",
    apiSubscriptionNFCCalculate: "/api/subscription/calculate",
    apiSubscriptionNFCCarAdd: "/api/subscription/car-add",
    apiSubscriptionNFCInvoice: "/api/subscription/invoice",
    apiSubscriptionNFCReject: "/api/subscription/reject",



    apiAppSettingAdd: "/api/app-setting/add",
    apiAppSettingEdit: "/api/app-setting/edit",
    apiAppSettingDetail: "/api/app-setting/detail",
    apiAppSettingDelete: "/api/app-setting/delete",

    apiOdometerRecordAdd: "/api/odometer-record/add",
    apiOdometerRecordEdit: "/api/odometer-record/edit",
    apiOdometerRecordGet: "/api/odometer-record/get",
    apiOdometerRecordDetail: "/api/odometer-record/detail",
    apiOdometerRecordDelete: "/api/odometer-record/delete",

    apiMenuAdd: "/api/menu/add",
    apiMenuEdit: "/api/menu/edit",
    apiMenuGet: "/api/menu/get",
    apiMenuDetail: "/api/menu/detail",
    apiMenuDelete: "/api/menu/delete",
    apiMenuList: "/api/menu/list",
    apiMenuActive: "/api/menu/active",
    apiMenuTree: "/api/menu/tree",

    apiEmployeeAdd: "/api/employee/add",
    apiEmployeeEdit: "/api/employee/edit",
    apiEmployeeGet: "/api/employee/get",
    apiEmployeeDetail: "/api/employee/detail",
    apiEmployeeDelete: "/api/employee/delete",
    apiEmployeeList: "/api/employee/list",
    apiEmployeeActive: "/api/employee/active",

    apiEmployeeMenuAdd: "/api/employee-menu/add",
    apiEmployeeMenuDetail: "/api/employee-menu/detail",
    apiEmployeeMenuTree: "/api/employee-menu/tree",

    apiAccountBalanceGet: "/api/account-balance/get",
    apiInvoiceSummaryGet: "/api/invoice-summary/get",
    apiInvoiceSummaryByCarGet:"/api/invoice-summarybycar/get",
    apiInvoiceSummaryByBranchGet:"/api/invoice-summarybybranch/get",
    apiInvoiceReviewGet: "/api/invoice-review/get",
    
    apiInvoiceDetailGet: "/api/invoice-detail/get",
    apiCarBalanceGet: "/api/car-balance/get",
    apiStationReportGet: "/api/station-report/get",
    apiStationSaleGet: "/api/station-sale/get",
    apiStationStatementGet: "/api/station-statement/get",
    apiCarTransactionGet: "/api/car-transaction/get",
    apiPetrolStationListGet: "/api/petrol-station-list/get",
    apiCarConsumptionRateGet: "/api/car-consumption-rate/get",
    apiCarKmConsumptionGet: "/api/car-km-consumption/get",
    apiCarOdometerMaxGet: "/api/car-odometer-max/get",
    apiCarListReportGet: "/api/car-list-report/get",

    
    apiCarOdometerMinGet: "/api/car-odometer-min/get",
    apiOdometerBetweenDateGet: "/api/odometer-between-date/get",
    apiOdometerHistoryGet: "/api/odometer-history/get",
    apiCustomerStatementGet: "/api/customer-statement/get",

    apiCustomerStatementSummaryGet: "/api/customer-statement-summary/get",
    
    apiCompanyBranchStatementGet: "/api/company-branch-statement/get",

    apiPetropayAccountGet: "/api/petropay-account/get",
    apiPetropayAccountList: "/api/petropay-account/list",
    apiPetropayAccountPayment: "/api/petropay-account/payment",

    apiServiceMasterList: "/api/service-master/list",

    apiTransferBalance: "/api/transfer-balance",
    apiTransferBalanceCarBatch: "/api/transfer-balance/car-batch",

    apiPromotionCouponAdd: "/api/promotion-coupon/add",
    apiPromotionCouponEdit: "/api/promotion-coupon/edit",
    apiPromotionCouponGet: "/api/promotion-coupon/get",
    apiPromotionCouponDetail: "/api/promotion-coupon/detail",
    apiPromotionCouponDelete: "/api/promotion-coupon/delete",


    apiNewCustomerAdd: "/api/new-customer/add",
    apiNewCustomerEdit: "/api/new-customer/edit",
    apiNewCustomerGet: "/api/new-customer/get",
    apiNewCustomerDetail: "/api/new-customer/detail",
    apiNewCustomerDelete: "/api/new-customer/delete",
    apiNewCustomerList: "/api/new-customer/list",
    apiNewCustomerActive: "/api/new-customer/active",

    apiCarTypeMasterList: "/api/car-type-master/list",
    apiCarModelMasterList: "/api/car-model-master/list",
    apiCarBrandMasterList: "/api/car-brand-master/list",
    apiPetrolPriceList: "/api/petrol-price/list",
    apiPricePackageList: "/api/Price-package/list",

    apiTransferBonusGet: "/api/transfer-bonus/get",
    apiTransferBonusAdd: "/api/transfer-bonus/add",

    apiDashboardCustomerGet: "/api/dashboard/customer",
    apiDashboardSupplierGet: "/api/dashboard/supplier",
    apiDashboardAdminGet: "/api/dashboard/admin",

    apiCurrentUserBalanceGet: "/api/current-user-balance/get",

    apiLog: "/api/log"
}
