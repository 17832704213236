import IDeserialize from "../../../../app/interfaces/deserialize";
import {makeAutoObservable} from "mobx";

export default class CarUserListItem implements IDeserialize
{
    key: number;
    carUserName: string;
    carUserEmpID: number;
   carUserPhoneNumber: string;
    carUserPassword: number;
    carID: number;
    //carUserMonthlyBudget: number;


    constructor() {
        makeAutoObservable(this);
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
