import IDeserialize from "app/interfaces/deserialize";
import {makeAutoObservable} from "mobx";
import CarUserListItem from "./CarUserListItem";

export default class ListCarUserResponse implements IDeserialize
{
    items: CarUserListItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    deserialize(input: any): this
    {
        Object.assign(this, input);

        this.items = this.items.map(x => new CarUserListItem().deserialize(x));

        return this;
    }
}
