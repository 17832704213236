import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import ListCompanyViewModel from "../../../entities/companies/view-models/ListCompanyViewModel";
import NavigationService from "app/services/NavigationService";
import UserContext from "identity/contexts/UserContext";
import GetCustomerStatementSummaryViewModel from "../view-models/GetCustomerStatementSummaryViewModel";

export default class CustomerStatementSummaryStore
{
    getCustomerStatementSummaryViewModel: GetCustomerStatementSummaryViewModel;
    listCompanyViewModel: ListCompanyViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onCustomerStatementSummaryGetPageLoad()
    {
        if( UserContext.info.role !== 1  && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getCustomerStatementSummaryViewModel = new GetCustomerStatementSummaryViewModel(this);
        this.listCompanyViewModel = new ListCompanyViewModel();
    }

    onCustomerStatementSummaryGetPageUnload()
    {
        this.getCustomerStatementSummaryViewModel = null;
        this.listCompanyViewModel = null;
    }

}
