export default
[
 /*{title: "CarListReportes.Label.key", dataIndex: "key", key: "key", responsive: ['md']},*/
 {title: "Cars.Label.companyBarnchName", dataIndex: "companyBranchName", key: "companyBranchName", responsive: ['md']},
 {title: "CarListReports.Label.carId", dataIndex: "carId", key: "carId", responsive: ['md']},
 {title: "CarListReports.Label.carIdNumber", dataIndex: "carIdNumber", key: "carIdNumber", responsive: ['md']},

// {title: "Cars.Label.carBrand", dataIndex: "carBrand", key: "carBrand", responsive: ['md']},
 {title: "Cars.Label.carModel", dataIndex: "carModel", key: "carModel", responsive: ['md']},


 {title: "Cars.Label.carDriverName", dataIndex: "carDriverName", key: "carDriverName", responsive: ['md']},
 {title: "Cars.Label.consumptionType", dataIndex: "consumptionType", key: "consumptionType", width: "100px"},

 {title: "Cars.Label.consumptionMethod", dataIndex: "consumptionMethod", key: "consumptionMethod", responsive: ['md']},
 {title: "Cars.Label.consumptionValue", dataIndex: "consumptionValue", key: "consumptionValue", responsive: ['md']},
 
 {title: "Cars.Label.carBalnce", dataIndex: "carBalnce", key: "carBalnce",responsive: ['md']},

// {title: "Cars.Label.carCunsumptionRate", dataIndex: "carConsumptionRate", key: "carConsumptionRate", responsive: ['md']},
 {title: "Cars.Label.carFuelKmCap", dataIndex: "carFuelKmCap", key: "carFuelKmCap", responsive: ['md']},
 {title: "Cars.Label.carOdometerRecordRequired", dataIndex: "carOdometerRecordRequired", key: "carOdometerRecordRequired",responsive: ['md']},
{title: "Cars.Label.carAutoRecharge", dataIndex: "carAutoRecharge", key: "carAutoRecharge", responsive: ['md']},
 {title: "Cars.Label.carAutoRechargeAmountCap", dataIndex: "carAutoRechargeAmountCap", key: "carAutoRechargeAmountCap", responsive: ['md']},

 {title: "Cars.Label.carSmartFuel", dataIndex: "forecastSystem", key: "forecastSystem", responsive: ['md']},

]

