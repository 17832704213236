export default
[
    /*{title: "InvoiceSummaries.Label.key", dataIndex: "key", key: "key", responsive: ['md']},*/
    
    {title: "InvoiceSummaries.Label.companyBranchName", dataIndex: "companyBranchName", key: "companyBranchName", responsive: ['md']},
    {title: "InvoiceSummaries.Label.carIdNumber", dataIndex: "carIdNumber", key: "carIdNumber", responsive: ['md']},
 
    {title: "InvoiceSummaries.Label.invoiceFuelConsumptionLiter", dataIndex: "invoiceFuelConsumptionLiter", key: "invoiceFuelConsumptionLiter", responsive: ['md']},
    {title: "InvoiceSummaries.Label.invoiceAmount", dataIndex: "invoiceAmount", key: "invoiceAmount", responsive: ['md']},
    {title: "InvoiceSummaries.Label.invoiceServiceFees", dataIndex: "invoiceServiceFees", key: "invoiceServiceFees", responsive: ['md']},
    {title: "General.Table.Total", dataIndex: "totalInvoiceAndFees", key: "totalInvoiceAndFees", responsive: ['md']},
    {title: "Cars.Label.consumptionValue", dataIndex: "consumptionValue", key: "consumptionValue", responsive: ['md']}
    
]


