import { makeAutoObservable } from "mobx";

export default class GetCarListReportRequest
{
    carId?: number;
    carIdNumber: string;

    companyId: number;
    companyBranchId: number;
    companyBranchName: string;
    consumptionType: string;
    
    carOdometerRecordRequired: boolean;
    carAutoRecharge :boolean;
    forecastSystem:boolean;
  
    exportToFile: boolean;

    pageIndex: number;
    pageSize: number;

    constructor(
    ) {
        makeAutoObservable(this);
    }
}
