import { Tag } from "antd";
import { color } from "highcharts";

export default
[
   
    {title: "InvoiceSummaries.Label.invoiceId", dataIndex: "invoiceNumber", key: "invoiceNumber", responsive: ['md']},
    /*{title: "InvoiceSummaries.Label.companyBranchId", dataIndex: "companyBranchId", key: "companyBranchId", responsive: ['md']},*/
    {title: "InvoiceSummaries.Label.companyBranchName", dataIndex: "companyBranchName", key: "companyBranchName", responsive: ['md'], 

        
    },


   // {title: "InvoiceSummaries.Label.invoiceDataTime", dataIndex: "invoiceDataTime", key: "invoiceDataTime", responsive: ['md']},
   
    {title: "InvoiceSummaries.Label.carIdNumber", dataIndex: "carIdNumber", key: "carIdNumber", responsive: ['md']},

  
    {title: "CustomerDashboard.Section.invoiceConsumption", dataIndex: "invoiceConsumption", key: "invoiceConsumption", responsive: ['md']

       , sorter: (a, b) => a.invoiceConsumption - b.invoiceConsumption

    },
    {title: "CustomerDashboard.Section.previousInvoiceConsumption", dataIndex: "lastInvoiceConsmptionRate", key: "lastInvoiceConsmptionRate", responsive: ['md']

        , sorter: (a, b) => a.lastInvoiceConsmptionRate - b.lastInvoiceConsmptionRate
    },

    {title: "CustomerDashboard.Section.consumptio60DaysRate", dataIndex: "consumptio60DaysRate", key: "consumptio60DaysRate", responsive: ['md']

        , sorter: (a, b) => a.consumptio60DaysRate - b.consumptio60DaysRate
    },

  

]

