// eslint-disable-next-line import/no-anonymous-default-export
export default
[
    
    {title: "AdminDashboard.PetrolStationItem.Label.key", dataIndex: "key", key: "key"},
   
    {title: "PetrolStationLists.Label.stationLucationName", dataIndex: "stationLucationName", key: "stationLucationName", responsive: ['md']},
    
    {title: "AdminDashboard.PetrolStationItem.Label.name", dataIndex: "name", key: "name"},
    {title: "AdminDashboard.PetrolStationItem.Label.balance", dataIndex: "balance", 
    
    
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.balance - b.balance , render: value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser:value => value!.replace(/\$\s?|(,*)/g, '') ,
    
     key: "balance"},

    {title: "AdminDashboard.PetrolStationItem.Label.bonus", dataIndex: "bonus",
    render: value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser:value => value!.replace(/\$\s?|(,*)/g, '') ,
    key: "bonus"}

]


