
import {makeAutoObservable} from "mobx";
import {getLocalizedString} from "../../../app/utils/Localization";
import ListCarUserRequest from "../handlers/List/ListCarUserRequest";
import ListCarUserResponse from "../handlers/List/ListCarUserResponse";
import i18next from "i18next";
import log from "loglevel";
import ListCarUserHandler from "../handlers/List/ListCarUserHandler";


export default class ListCarUseViewModel {
    
    isProcessing: boolean;
    errorMessage: string;
    listCarUserResponse: ListCarUserResponse = new ListCarUserResponse();


    constructor() {
        makeAutoObservable(this);
    }

    public async getCarUseList(carId?: number)  {
        try {
            
            this.isProcessing = true;

            let request = new ListCarUserRequest(carId);
            let response = await ListCarUserHandler.get(request);

            if (response && response.success) {

                this.listCarUserResponse = new ListCarUserResponse();
                let result = response.data;
                //let items = result.items;
                this.listCarUserResponse.items = result;

                return this.listCarUserResponse;
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('CarUses.Error.List.Message');
            log.error(e);
        } finally {
            this.isProcessing = false;
        }
    }
}
