import InvoiceSummaryByBranchItem from "../handlers/get/InvoiceSummaryByBranchItem";
import InvoiceSummaryByBranchStore from "../stores/InvoiceSummaryByBranchStore";
import {makeAutoObservable} from "mobx";

import GetInvoiceSummaryByBranchHandler from "../handlers/get/GetInvoiceSummaryByBranchHandler";

import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import {message} from "antd";
import UserContext from "../../../identity/contexts/UserContext";
import ObjectHelper from "../../../app/utils/ObjectHelper";
import GetInvoiceSummaryByBranchRequest from "../handlers/get/GetInvoiceSummaryByBranchRequest";

export default class GetInvoiceSummaryByBranchViewModel {
    columns: any[];
    invoiceSummaryByBranchList: InvoiceSummaryByBranchItem[];
    invoiceSummaryByBranchExport: InvoiceSummaryByBranchItem[];
    totalSize: number;
    sumInvoiceAmount: number;
    sumServiceFeesAmount: number;
    sumInvoiceFuelConsumptionLiter: number;
    sumTotalInvoiceAndFees:number;
    isProcessing: boolean;
    errorMessage: string;
    getInvoiceSummariesByBranchRequest: GetInvoiceSummaryByBranchRequest = new GetInvoiceSummaryByBranchRequest();

    constructor(public invoiceSummaryByBranchStore: InvoiceSummaryByBranchStore) {
        makeAutoObservable(this);

    }

    public async getAllInvoiceSummaryByBranch(getInvoiceSummariesByBranchRequest: GetInvoiceSummaryByBranchRequest, exportToFile: boolean = false) {
        try {
            this.errorMessage = "";
            if(ObjectHelper.isNullOrEmptyWithDefaultExceptions(getInvoiceSummariesByBranchRequest, [])){
                this.errorMessage = i18next.t("General.Search.AtLeastOne");
                return;
            }
            this.isProcessing = true;
            if(exportToFile)
                getInvoiceSummariesByBranchRequest.exportToFile = exportToFile;
            let response = await GetInvoiceSummaryByBranchHandler.get(getInvoiceSummariesByBranchRequest);

            if (response && response.success) {

                let result = response.data;
                let items = result.items;
                if(exportToFile)
                    this.invoiceSummaryByBranchExport = items;
                else {
                    this.invoiceSummaryByBranchList = items;
                    this.totalSize = result.totalCount;
                    this.sumInvoiceAmount = result.sumInvoiceAmount;
                    this.sumServiceFeesAmount = result.sumServiceFeesAmount;
                    this.sumInvoiceFuelConsumptionLiter=result.sumInvoiceFuelConsumptionLiter;
                    this.sumTotalInvoiceAndFees=result.sumTotalInvoiceAndFees;
                }
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('InvoiceSummaries.Error.Get.Message');
            log.error(e);
        } finally {
            getInvoiceSummariesByBranchRequest.exportToFile = false;
            this.isProcessing = false;
        }
    }

}
