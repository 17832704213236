import {makeAutoObservable} from "mobx";

export default class DeleteCarUserRequest
{
    public  carUserID: number;
   
    constructor(
    ) {
        makeAutoObservable(this);
    }
}
