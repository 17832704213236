import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import GetCarConsumptionRateViewModel from "../view-models/GetCarConsumptionRateViewModel";
import ListBranchViewModel from "../../../entities/branches/view-models/ListBranchViewModel";
import ListCarViewModel from "../../../entities/cars/view-models/ListCarViewModel";
import ListCompanyViewModel from "../../../entities/companies/view-models/ListCompanyViewModel";
import UserContext from "identity/contexts/UserContext";
import NavigationService from "app/services/NavigationService";
import ListCarModelMasterViewModel from "entities/CarModelMasters/view-models/ListCarModelMasterViewModel";
import ListCarBrandMasterViewModel from "entities/CarBrandMasters/view-models/ListCarBrandMasterViewModel";

export default class CarConsumptionRateStore
{
    getCarConsumptionRateViewModel: GetCarConsumptionRateViewModel;
    listBranchViewModel: ListBranchViewModel;
    listCarViewModel: ListCarViewModel;
    listCompanyViewModel: ListCompanyViewModel;
    listCarBrandMasterViewModel: ListCarBrandMasterViewModel;
    listCarModelMasterViewModel: ListCarModelMasterViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onCarConsumptionRateGetPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 5 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getCarConsumptionRateViewModel = new GetCarConsumptionRateViewModel(this);
        this.listBranchViewModel = new ListBranchViewModel();
        this.listCarViewModel = new ListCarViewModel();
        this.listCarBrandMasterViewModel = new ListCarBrandMasterViewModel();
        this.listCompanyViewModel= new ListCompanyViewModel();

        this.listCarModelMasterViewModel = new ListCarModelMasterViewModel();
    }

    onCarConsumptionRateGetPageUnload()
    {
        this.getCarConsumptionRateViewModel = null;
        this.listBranchViewModel = null;
        this.listCarViewModel = null;
        this.listCompanyViewModel = null;
        this.listCarBrandMasterViewModel = null;
        this.listCarModelMasterViewModel = null;
    }

}
