import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";

import ListBranchViewModel from "../../../entities/branches/view-models/ListBranchViewModel";
import ListCarViewModel from "../../../entities/cars/view-models/ListCarViewModel";
import ListServiceMasterViewModel from "../../../entities/ServiceMasters/view-models/ListServiceMasterViewModel";
import ListCompanyViewModel from "../../../entities/companies/view-models/ListCompanyViewModel";
import GetInvoiceSummaryByBranchViewModel from "../view-models/GetInvoiceSummaryByBranchViewModel";

export default class InvoiceSummaryByBranchStore
{
    getInvoiceSummaryByBranchViewModel: GetInvoiceSummaryByBranchViewModel;

    listBranchViewModel: ListBranchViewModel;
    listCarViewModel: ListCarViewModel;
    listServiceMasterViewModel: ListServiceMasterViewModel;
    listCompanyViewModel: ListCompanyViewModel;

    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onInvoiceSummaryByBranchGetPageLoad()
    {
        this.getInvoiceSummaryByBranchViewModel = new GetInvoiceSummaryByBranchViewModel(this);
        this.listBranchViewModel = new ListBranchViewModel();
        this.listCarViewModel = new ListCarViewModel();
        this.listServiceMasterViewModel = new ListServiceMasterViewModel();
        this.listCompanyViewModel = new ListCompanyViewModel();
    }

    onInvoiceSummaryByBranchGetPageUnload()
    {
        this.getInvoiceSummaryByBranchViewModel = null;
        this.listBranchViewModel = null;
        this.listCarViewModel = null;
        this.listServiceMasterViewModel = null;
        this.listCompanyViewModel = null;
    }

}
