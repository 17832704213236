import ApiResponse from "app/models/ApiResponse";
import ApiService from "app/services/ApiService";
import Endpoints from "app/constants/Endpoints";
import GetInvoiceSummaryByBranchRequest from "./GetInvoiceSummaryByBranchRequest";



export default class GetInvoiceSummaryByBranchHandler
{
    public static async get(request: GetInvoiceSummaryByBranchRequest): Promise<ApiResponse>
    {
        let response = await ApiService.post(Endpoints.apiInvoiceSummaryByBranchGet, request, true);
   
        return response;
    }
}
