import IDeserialize from "app/interfaces/deserialize";

export default class DetailOilPriceResponse implements IDeserialize
{
    
    productID: number;
    productDescriptionAR: string;
    productDescriptionEN: string;
    productCostPrice: number;
    productSelingPrice: number;
   // productActive: boolean;
    productLiter: number;
    

    petrol_Company_id : number;
    product_Active : boolean;

    serviceId: number;


    deserialize(input: any): this
    {
        Object.assign(this, input);
        return this;
    }
}
