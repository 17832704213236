import NavigationService from "app/services/NavigationService";
import {AppStore} from "app/stores/AppStore";
import PricePackageViewModel from "entities/PricePackages/view-models/PricePackageViewModel";
import UserContext from "identity/contexts/UserContext";
import {makeAutoObservable} from "mobx";
import EditOilPriceViewModel from "../view-models/EditOilPriceViewModel";
import GetOilPriceViewModel from "../view-models/GetOilPriceViewModel";
import ListPetrolCompanyViewModel from "entities/petrol-companies/view-models/ListPetrolCompanyViewModel";
import ListServiceMasterViewModel from "entities/ServiceMasters/view-models/ListServiceMasterViewModel";
export default class OilPricesStore
{
    getOilPriceViewModel: GetOilPriceViewModel;
   
    editOilPriceViewModel :EditOilPriceViewModel;

    listPetrolCompanyViewModel: ListPetrolCompanyViewModel;

    listServiceMasterViewModel: ListServiceMasterViewModel;

   // PricePackageViewModel:PricePackageViewModel
    static editOilPriceViewModel: any;
   
   
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onOilPriceGetPageLoad()
    {
        this.getOilPriceViewModel = new GetOilPriceViewModel(this);

        if( UserContext.info.role !== 100  )
            {
               
        
                 NavigationService.navigate('/');
                
        
        }
    }

    onOilPriceGetPageUnload()
    {
        this.getOilPriceViewModel = null;
    }

    onOilPriceEditPageLoad()
    {
        
        if( UserContext.info.role !== 100  )
        {
           
    
             NavigationService.navigate('/');
            
    
    }

        this.editOilPriceViewModel = new EditOilPriceViewModel(this);
     //   this.PricePackageViewModel = new PricePackageViewModel();
  
     this.listPetrolCompanyViewModel = new ListPetrolCompanyViewModel();
     this.listServiceMasterViewModel=new ListServiceMasterViewModel();

    }

    onOilPriceEditPageUnload()
    {
        this.editOilPriceViewModel = null;
        this.listPetrolCompanyViewModel =null;
        this.listServiceMasterViewModel=null;
       // this.PricePackageViewModel=null;
        
    }

}
