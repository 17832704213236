import {makeAutoObservable} from "mobx";

import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import {message} from "antd";
import UserContext from "../../../identity/contexts/UserContext";
import CustomerStatementSummaryItem from "../handlers/get/CustomerStatementSummaryItem";
import GetCustomerStatementSummaryRequest from "../handlers/get/GetCustomerStatementSummaryRequest";
import CustomerStatementSummaryStore from "../stores/CustomerStatementSummaryStore";
import GetCustomerStatementSummaryHandler from "../handlers/get/GetCustomerStatementSummaryHandler";

export default class GetCustomerStatementSummaryViewModel {
    columns: any[];
    customerStatementSummaryList: CustomerStatementSummaryItem[];
    customerStatementSummaryExport: CustomerStatementSummaryItem[];
    sumCustomerStatementSummary: number;
    totalSize: number;
    isProcessing: boolean;
    errorMessage: string;
    getCustomerStatementSummarysRequest: GetCustomerStatementSummaryRequest;

    constructor(public customerStatementSummaryStore: CustomerStatementSummaryStore) {
        makeAutoObservable(this);

    }

    public async getAllCustomerStatementSummary(getCustomerStatementSummarysRequest: GetCustomerStatementSummaryRequest, exportToFile: boolean = false) {
        try {
            this.isProcessing = true;
            getCustomerStatementSummarysRequest.exportToFile = exportToFile;
            let response = await GetCustomerStatementSummaryHandler.get(getCustomerStatementSummarysRequest);

            if (response && response.success) {

                let result = response.data;
                let items = result.items;
                if(exportToFile)
                    this.customerStatementSummaryExport = items;
                else {
                    this.customerStatementSummaryList = items;
                    this.totalSize = result.totalCount;
                    this.sumCustomerStatementSummary = result.sumCustomerStatementSummary;
                }
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('CustomerStatements.Error.Get.Message');
            log.error(e);
        } finally {
            getCustomerStatementSummarysRequest.exportToFile = false;
            this.isProcessing = false;
        }
    }

}
