import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import { Link } from "react-router-dom";
import "./InvoiceSummaryByBranchList.scss";
import Stores from "app/constants/Stores";

import {
    Button, Collapse, Col, Row,
    Pagination, Input, Form,
    Table, PageHeader, Space, DatePicker, Select, Alert, Spin
} from "antd";
import {
    FolderViewOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
import i18next from "i18next";
import GetInvoiceSummaryByBranchRequest from "../../handlers/get/GetInvoiceSummaryByBranchRequest";
import UserContext from "../../../../identity/contexts/UserContext";
import InvoiceSummaryByBranchColumns from "./InvoiceSummaryByBranchColumns";
import InvoiceSummaryByBranchStore from "../../stores/InvoiceSummaryByBranchStore";

import ExportExcel from "../../../../app/utils/ExportExcel";
import Constants from "../../../../app/constants/Constants";
import { color } from 'highcharts';

const { Panel } = Collapse;
const { Option } = Select;

interface InvoiceSummaryByBranchListProps {
    invoiceSummaryByBranchStore?: InvoiceSummaryByBranchStore
    
    
}

const InvoiceSummaryByBranchList: React.FC<InvoiceSummaryByBranchListProps> = inject(Stores.invoiceSummaryByBranchStore)(observer(({invoiceSummaryByBranchStore}) => {
    const [dataFetched, setDataFetched] = React.useState(false);
    const [carOptions, setCarOptions] = React.useState([]);
    const [branchOptions, setBranchOptions] = React.useState([]);
    const [companyOptions, setCompanyOptions] = React.useState([]);
    const [serviceMasterOptions, setServiceMasterOptions] = React.useState([]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };
    const [form] = Form.useForm();
    InvoiceSummaryByBranchColumns.forEach(w => {
       w.title = i18next.t(w.title) ;
    });

   const companyColumn = {title: i18next.t("InvoiceSummaries.Label.companyName"), dataIndex: "companyName", key: "companyName", responsive: ['md']};
    const columns: any[] = [...InvoiceSummaryByBranchColumns ,
        {
           
    }];
    if(UserContext.info.role == 100)
    {
       columns.unshift(companyColumn);
       
    }

    useEffect(() => {
        onLoad();

        return onUnload;
    }, []);

    async function onLoad() {
        invoiceSummaryByBranchStore.onInvoiceSummaryByBranchGetPageLoad();
        invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getInvoiceSummariesByBranchRequest = new GetInvoiceSummaryByBranchRequest();
        invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getInvoiceSummariesByBranchRequest.pageSize = 20;
        invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getInvoiceSummariesByBranchRequest.pageIndex = 0;
        invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getInvoiceSummariesByBranchRequest.companyId = UserContext.info.role === 1 ? UserContext.info.id : undefined;
        invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getInvoiceSummariesByBranchRequest.companyBranchId = UserContext.info.role === 5 ? UserContext.info.id : undefined;


        try {
            if(UserContext.info.role === 100) {
                await invoiceSummaryByBranchStore.listCompanyViewModel.getCompanyList();
                let companyOptions = [];
                if (invoiceSummaryByBranchStore.listCompanyViewModel) {
                    for (let item of invoiceSummaryByBranchStore.listCompanyViewModel.listCompanyResponse.items) {
                        companyOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setCompanyOptions(companyOptions);
            }
            if(UserContext.info.role === 1) {
                await invoiceSummaryByBranchStore.listBranchViewModel.getBranchList();
                let branchOptions = [];
                if (invoiceSummaryByBranchStore.listBranchViewModel) {
                    for (let item of invoiceSummaryByBranchStore.listBranchViewModel.listBranchResponse.items) {
                        branchOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setBranchOptions(branchOptions);
            }

            await invoiceSummaryByBranchStore.listCarViewModel.getCarList();
            let carOptions = [];
            if (invoiceSummaryByBranchStore.listCarViewModel) {
                if(UserContext.info.role !== 5) {
                    for (let item of invoiceSummaryByBranchStore.listCarViewModel.listCarResponse.items) {
                        carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
                    }
                }
                else{
                    const filteredCars = invoiceSummaryByBranchStore.listCarViewModel.listCarResponse.items.filter(w => w.branchId == UserContext.info.id);
                    for (let item of filteredCars) {
                        carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
                    }
                }
            }
            setCarOptions(carOptions);

            await invoiceSummaryByBranchStore.listServiceMasterViewModel.getServiceMasterList();
            let serviceMasterOptions = [];
            if (invoiceSummaryByBranchStore.listServiceMasterViewModel) {
                for (let item of invoiceSummaryByBranchStore.listServiceMasterViewModel.listServiceMasterResponse.items) {
                    serviceMasterOptions.push(<Option key={item.key} value={item.title}>{item.title}</Option>);
                }
            }
            setServiceMasterOptions(serviceMasterOptions);

            setDataFetched(true);
         //   await invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getAllInvoiceSummaryByBranch(invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getInvoiceSummariesByCarRequest);
        }
        catch {

        }
    }

    let viewModel = invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel;

    if (!viewModel) return;

    function onUnload() {
        invoiceSummaryByBranchStore.onInvoiceSummaryByBranchGetPageUnload();
        setServiceMasterOptions([]);
        setCompanyOptions([]);
        setBranchOptions([]);
        setCarOptions([]);
    }

    async function pageIndexChanged(pageIndex, pageSize){
        viewModel.getInvoiceSummariesByBranchRequest.pageSize = pageSize;
        viewModel.getInvoiceSummariesByBranchRequest.pageIndex = pageIndex - 1;
        await invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getAllInvoiceSummaryByBranch(viewModel.getInvoiceSummariesByBranchRequest);
    }
    async function pageSizeChanged(current, pageSize){
        viewModel.getInvoiceSummariesByBranchRequest.pageSize = pageSize;
        viewModel.getInvoiceSummariesByBranchRequest.pageIndex = 0;
        await invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getAllInvoiceSummaryByBranch(viewModel.getInvoiceSummariesByBranchRequest);
    }
    function onChanged(e){
        viewModel.getInvoiceSummariesByBranchRequest[`${e.target.id}`] = e.target.value;
    }

    function onSelectChanged(e, propName){
        
        viewModel.getInvoiceSummariesByBranchRequest[`${propName}`] = e;
        if(propName === "companyId") {
            let carOptions = [];
            const filteredCars = invoiceSummaryByBranchStore.listCarViewModel.listCarResponse.items.filter(w => w.companyId == e);
            for (let item of filteredCars) {
                carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
            }
            setCarOptions(carOptions);

            let branchOptions = [];
            const filteredBranches = invoiceSummaryByBranchStore.listBranchViewModel.listBranchResponse.items.filter(w => w.companyId == e);
            for (let item of filteredBranches) {
                branchOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
            }
            setBranchOptions(branchOptions);
        }
        if(propName === "companyBranchId") {
            let carOptions = [];
            const filteredCars = invoiceSummaryByBranchStore.listCarViewModel.listCarResponse.items.filter(w => w.branchId == e);
            for (let item of filteredCars) {
                carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
            }
            setCarOptions(carOptions);
        }
    }
    function onDateChange(date, dateString, prop) {
        viewModel.getInvoiceSummariesByBranchRequest[`${prop}`] = dateString;
    }
    async function onFinish(values: any) {
        viewModel.getInvoiceSummariesByBranchRequest.pageIndex = 0;
        await viewModel.getAllInvoiceSummaryByBranch(viewModel.getInvoiceSummariesByBranchRequest);
    }
    async function onReset(){
        const pageSize = viewModel.getInvoiceSummariesByBranchRequest.pageSize;
        viewModel.getInvoiceSummariesByBranchRequest = new GetInvoiceSummaryByBranchRequest();
        viewModel.getInvoiceSummariesByBranchRequest.pageIndex = 0;
        viewModel.getInvoiceSummariesByBranchRequest.pageSize = pageSize;
        if(UserContext.info.role == 1){
            invoiceSummaryByBranchStore.getInvoiceSummaryByBranchViewModel.getInvoiceSummariesByBranchRequest.companyId = UserContext.info.id;
        }
        await viewModel.getAllInvoiceSummaryByBranch(viewModel.getInvoiceSummariesByBranchRequest);
        form.resetFields();
    }
    async function ExportToExcel(){
        viewModel.invoiceSummaryByBranchExport = [];
        await viewModel.getAllInvoiceSummaryByBranch(viewModel.getInvoiceSummariesByBranchRequest, true);
        if(viewModel.invoiceSummaryByBranchExport && viewModel.invoiceSummaryByBranchExport?.length > 0) {
            columns.pop();
            ExportExcel(columns, viewModel?.invoiceSummaryByBranchExport, "InvoiceSummaryByBranch");
        }
    }

    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={i18next.t("InvoiceSummariesByBranch.Page.Title")}
                subTitle={i18next.t("InvoiceSummariesByBranch.Page.SubTitle")}
                extra={[
                    <Button className={"button-section"} key={"ExportExcel"} type="primary" loading={viewModel?.isProcessing} icon={<FileExcelOutlined />} onClick={ExportToExcel}>
                        {i18next.t("General.Button.ExportExcel")}
                    </Button>
                    ,
                ]}
            />

            <Collapse defaultActiveKey={['1']} className='searchPanelFotamt' >
                <Panel header={i18next.t("General.SearchPanel.Text")} key="1">
                    <Form {...formItemLayout} layout={"vertical"} onFinish={onFinish} form={form}
                          key={"searchForm"}
                          scrollToFirstError>
                        <div>
                        {dataFetched ?
                        <Row gutter={[24, 16]}>
                            {UserContext.info.role === 100 ?
                                <Col span={8}>
                                    <Form.Item name="companyId" initialValue={viewModel?.getInvoiceSummariesByBranchRequest?.companyId}
                                               key={"companyId"}
                                               label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyName")}>
                                        {/*<Input onChange={onChanged}/>*/}
                                        <Select style={{width: "100%", display:"block"}} allowClear={true}
                                                showSearch={true} onChange={(e) => onSelectChanged(e, "companyId")}>
                                            {companyOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>: ""}
                            {/*<Col span={8}>
                                <Form.Item name="companyBranchName" initialValue={viewModel?.getInvoiceSummariesRequest?.companyBranchName}
                                           key={"companyBranchName"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyBranchName")}>
                                    <Input onChange={onChanged}/>
                                    <Select style={{width: "100%", display:"block"}}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "companyBranchName")}>
                                        {petropayAccountOptions}
                                    </Select>
                                </Form.Item>
                            </Col>*/}
                            {UserContext.info.role === 1 ?
                            <Col span={8}>
                                <Form.Item name="companyBranchId" initialValue={viewModel?.getInvoiceSummariesByBranchRequest?.companyBranchId}
                                           key={"companyBranchId"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyBranchName")}>
                                    {/*<Input onChange={onChanged} />*/}
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "companyBranchId")}>
                                        {branchOptions}
                                    </Select>
                                </Form.Item>
                            </Col> : "" }
                            
                            


                            <Col span={8}>
                                <Form.Item name="serviceDescription" initialValue={viewModel?.getInvoiceSummariesByBranchRequest?.serviceDescription}
                                           key={"serviceDescription"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.serviceDescription")}>
                                    {/*<Input onChange={onChanged}/>*/}
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "serviceDescription")}>
                                        {serviceMasterOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item name="invoiceDataTimeFrom" initialValue={viewModel?.getInvoiceSummariesByBranchRequest?.invoiceDataTimeFrom}
                                           key={"invoiceDataTimeFrom"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.invoiceDataTimeFrom")}>
                                    <DatePicker format={Constants.dateFormat} onChange={((date, dateString) => onDateChange(date, dateString, "invoiceDataTimeFrom"))} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="invoiceDataTimeTo" initialValue={viewModel?.getInvoiceSummariesByBranchRequest?.invoiceDataTimeTo}
                                           key={"invoiceDataTimeTo"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.invoiceDataTimeTo")}>
                                    <DatePicker format={Constants.dateFormat} onChange={((date, dateString) => onDateChange(date, dateString, "invoiceDataTimeTo"))} />
                                </Form.Item>
                            </Col>
                                </Row>
                                    :
                                    <Row gutter={[24, 16]}>
                                        <Col offset={11} span={8}>
                                            <Spin className={"spine"} size="large" />
                                        </Col>
                                    </Row>}
                            </div>
                        <PageHeader
                            ghost={false}
                            subTitle={<div>
                                {viewModel?.errorMessage &&
                                <Alert message={viewModel?.errorMessage} type="error" />
                                }
                            </div>}
                            extra={[
                                <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} onClick={onReset} danger key="reset" size={"large"} htmlType="reset">
                                    {i18next.t("General.SearchPanel.ResetButton")}
                                </Button>,
                                <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} key="submit" size={"large"} htmlType="submit">
                                    {i18next.t("General.SearchPanel.SearchButton")}
                                </Button>
                            ]}
                        />
                    </Form>
                </Panel>
            </Collapse>
            <br/>
            <Table     dataSource={viewModel?.invoiceSummaryByBranchList} columns={columns} loading={viewModel?.isProcessing}
                   bordered={true} pagination={false} //sticky //scroll={{ x: 1500 }} 
                   summary= { () => (
                       <Table.Summary.Row className='totalTabl'> 
                           <Table.Summary.Cell  index={3}>{i18next.t("General.Table.Total")}</Table.Summary.Cell>
                           <Table.Summary.Cell colSpan={UserContext.info.role == 1 ? 0 : 1} index={0}></Table.Summary.Cell>
                           <Table.Summary.Cell index={1}>{viewModel?.sumInvoiceFuelConsumptionLiter?.toLocaleString()}</Table.Summary.Cell>
                           <Table.Summary.Cell index={2}>{viewModel?.sumInvoiceAmount?.toLocaleString()}</Table.Summary.Cell>
                           <Table.Summary.Cell index={3}>{viewModel?.sumServiceFeesAmount?.toLocaleString()}</Table.Summary.Cell>
                           <Table.Summary.Cell index={4}>{viewModel?.sumTotalInvoiceAndFees?.toLocaleString()} </Table.Summary.Cell>
                           <Table.Summary.Cell colSpan={1} index={0}></Table.Summary.Cell>
                       </Table.Summary.Row>
                   )} />
            <br/>
            <Pagination
                total={viewModel?.totalSize}
                showSizeChanger
                showQuickJumper
                defaultPageSize={20}
                onChange={pageIndexChanged}
                onShowSizeChange={pageSizeChanged}
                showTotal={total => `Total ${total} items`}
            />
        </div>
    )
}));


export default InvoiceSummaryByBranchList;


